// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkBindRequest
 */
export interface LinkBindRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkBindRequest
     */
    ref: number;
}

/**
 * Check if a given object implements the LinkBindRequest interface.
 */
export function instanceOfLinkBindRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ref" in value;

    return isInstance;
}

export function LinkBindRequestFromJSON(json: any): LinkBindRequest {
    return LinkBindRequestFromJSONTyped(json, false);
}

export function LinkBindRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkBindRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ref': json['ref'],
    };
}

export function LinkBindRequestToJSON(value?: LinkBindRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ref': value.ref,
    };
}

