// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarkViewedAllNotifications
 */
export interface MarkViewedAllNotifications {
    /**
     * 
     * @type {string}
     * @memberof MarkViewedAllNotifications
     */
    message: string;
}

/**
 * Check if a given object implements the MarkViewedAllNotifications interface.
 */
export function instanceOfMarkViewedAllNotifications(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function MarkViewedAllNotificationsFromJSON(json: any): MarkViewedAllNotifications {
    return MarkViewedAllNotificationsFromJSONTyped(json, false);
}

export function MarkViewedAllNotificationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkViewedAllNotifications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function MarkViewedAllNotificationsToJSON(value?: MarkViewedAllNotifications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}

