// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LadderGameStartRequest
 */
export interface LadderGameStartRequest {
    /**
     * 
     * @type {number}
     * @memberof LadderGameStartRequest
     */
    startRate: number;
    /**
     * 
     * @type {number}
     * @memberof LadderGameStartRequest
     */
    numberBombs: number;
}

/**
 * Check if a given object implements the LadderGameStartRequest interface.
 */
export function instanceOfLadderGameStartRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startRate" in value;
    isInstance = isInstance && "numberBombs" in value;

    return isInstance;
}

export function LadderGameStartRequestFromJSON(json: any): LadderGameStartRequest {
    return LadderGameStartRequestFromJSONTyped(json, false);
}

export function LadderGameStartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LadderGameStartRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startRate': json['start_rate'],
        'numberBombs': json['number_bombs'],
    };
}

export function LadderGameStartRequestToJSON(value?: LadderGameStartRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_rate': value.startRate,
        'number_bombs': value.numberBombs,
    };
}

