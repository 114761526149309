// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PUBGPack } from './PUBGPack';
import {
    PUBGPackFromJSON,
    PUBGPackFromJSONTyped,
    PUBGPackToJSON,
} from './PUBGPack';

/**
 * 
 * @export
 * @interface PreparedWithdrawalPUBGPack
 */
export interface PreparedWithdrawalPUBGPack {
    /**
     * 
     * @type {PUBGPack}
     * @memberof PreparedWithdrawalPUBGPack
     */
    pack: PUBGPack;
    /**
     * 
     * @type {number}
     * @memberof PreparedWithdrawalPUBGPack
     */
    quantity: number;
}

/**
 * Check if a given object implements the PreparedWithdrawalPUBGPack interface.
 */
export function instanceOfPreparedWithdrawalPUBGPack(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pack" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function PreparedWithdrawalPUBGPackFromJSON(json: any): PreparedWithdrawalPUBGPack {
    return PreparedWithdrawalPUBGPackFromJSONTyped(json, false);
}

export function PreparedWithdrawalPUBGPackFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreparedWithdrawalPUBGPack {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pack': PUBGPackFromJSON(json['pack']),
        'quantity': json['quantity'],
    };
}

export function PreparedWithdrawalPUBGPackToJSON(value?: PreparedWithdrawalPUBGPack | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pack': PUBGPackToJSON(value.pack),
        'quantity': value.quantity,
    };
}

