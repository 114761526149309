// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ffc430` - Золотой
 * * `ff3358` - Красный
 * * `ed65fc` - Розовый
 * * `a869ff` - Фиолетовый
 * * `6ec0ff` - Голубой
 * @export
 */
export const RobloxSubjectQualityEnum = {
    Ffc430: 'ffc430',
    Ff3358: 'ff3358',
    Ed65fc: 'ed65fc',
    A869ff: 'a869ff',
    _6ec0ff: '6ec0ff'
} as const;
export type RobloxSubjectQualityEnum = typeof RobloxSubjectQualityEnum[keyof typeof RobloxSubjectQualityEnum];


export function RobloxSubjectQualityEnumFromJSON(json: any): RobloxSubjectQualityEnum {
    return RobloxSubjectQualityEnumFromJSONTyped(json, false);
}

export function RobloxSubjectQualityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RobloxSubjectQualityEnum {
    return json as RobloxSubjectQualityEnum;
}

export function RobloxSubjectQualityEnumToJSON(value?: RobloxSubjectQualityEnum | null): any {
    return value as any;
}

