// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarLevel } from './CalendarLevel';
import {
    CalendarLevelFromJSON,
    CalendarLevelFromJSONTyped,
    CalendarLevelToJSON,
} from './CalendarLevel';

/**
 * 
 * @export
 * @interface CalendarEvent
 */
export interface CalendarEvent {
    /**
     * 
     * @type {Date}
     * @memberof CalendarEvent
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEvent
     */
    endDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    name: string;
    /**
     * 
     * @type {Array<CalendarLevel>}
     * @memberof CalendarEvent
     */
    readonly levels: Array<CalendarLevel>;
}

/**
 * Check if a given object implements the CalendarEvent interface.
 */
export function instanceOfCalendarEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "levels" in value;

    return isInstance;
}

export function CalendarEventFromJSON(json: any): CalendarEvent {
    return CalendarEventFromJSONTyped(json, false);
}

export function CalendarEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'name': json['name'],
        'levels': ((json['levels'] as Array<any>).map(CalendarLevelFromJSON)),
    };
}

export function CalendarEventToJSON(value?: CalendarEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': (value.startDate.toISOString()),
        'end_date': (value.endDate.toISOString()),
        'name': value.name,
    };
}

