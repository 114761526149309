// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleCase } from './SimpleCase';
import {
    SimpleCaseFromJSON,
    SimpleCaseFromJSONTyped,
    SimpleCaseToJSON,
} from './SimpleCase';

/**
 * 
 * @export
 * @interface BloggersBattleCases
 */
export interface BloggersBattleCases {
    /**
     * 
     * @type {SimpleCase}
     * @memberof BloggersBattleCases
     */
    _case: SimpleCase;
    /**
     * 
     * @type {number}
     * @memberof BloggersBattleCases
     */
    readonly percent: number;
}

/**
 * Check if a given object implements the BloggersBattleCases interface.
 */
export function instanceOfBloggersBattleCases(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "_case" in value;
    isInstance = isInstance && "percent" in value;

    return isInstance;
}

export function BloggersBattleCasesFromJSON(json: any): BloggersBattleCases {
    return BloggersBattleCasesFromJSONTyped(json, false);
}

export function BloggersBattleCasesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BloggersBattleCases {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_case': SimpleCaseFromJSON(json['case']),
        'percent': json['percent'],
    };
}

export function BloggersBattleCasesToJSON(value?: BloggersBattleCases | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'case': SimpleCaseToJSON(value._case),
    };
}

