import type { PlatformName } from "~/types/Platform";

export default defineNuxtRouteMiddleware((to) => {
  const platformParam = to.params.platform.toString();
  const { path, fullPath } = to;
  const platformCookie = useCookie("platform");
  const platformStore = usePlatformStore();
  if (platformStore.isPlatformLegal(platformParam)) {
    const platformName =
      (platformParam as PlatformName) || platformStore.DEFAULT_PLATFORM;
    platformStore.updatePlatformPath({ path, fullPath });
    if (platformName !== platformStore.currentPlatformName) {
      platformStore.currentPlatformName = platformName;
    }
    if (platformCookie.value && platformCookie.value !== platformName) {
      return platformStore.changePlatform(platformCookie.value as PlatformName);
    }
  } else {
    throw createError({ statusCode: 404 });
  }
});
