// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MomentRewardInfo
 */
export interface MomentRewardInfo {
    /**
     * 
     * @type {string}
     * @memberof MomentRewardInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MomentRewardInfo
     */
    img3d: string;
    /**
     * 
     * @type {boolean}
     * @memberof MomentRewardInfo
     */
    readonly isAvailable: boolean;
}

/**
 * Check if a given object implements the MomentRewardInfo interface.
 */
export function instanceOfMomentRewardInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "img3d" in value;
    isInstance = isInstance && "isAvailable" in value;

    return isInstance;
}

export function MomentRewardInfoFromJSON(json: any): MomentRewardInfo {
    return MomentRewardInfoFromJSONTyped(json, false);
}

export function MomentRewardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MomentRewardInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'img3d': json['img_3d'],
        'isAvailable': json['is_available'],
    };
}

export function MomentRewardInfoToJSON(value?: MomentRewardInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'img_3d': value.img3d,
    };
}

