// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RewardRangeRewardBase } from './RewardRangeRewardBase';
import {
    RewardRangeRewardBaseFromJSON,
    RewardRangeRewardBaseFromJSONTyped,
    RewardRangeRewardBaseToJSON,
} from './RewardRangeRewardBase';

/**
 * 
 * @export
 * @interface RewardRange
 */
export interface RewardRange {
    /**
     * 
     * @type {RewardRangeRewardBase}
     * @memberof RewardRange
     */
    reward: RewardRangeRewardBase;
    /**
     * 
     * @type {number}
     * @memberof RewardRange
     */
    end: number;
}

/**
 * Check if a given object implements the RewardRange interface.
 */
export function instanceOfRewardRange(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reward" in value;
    isInstance = isInstance && "end" in value;

    return isInstance;
}

export function RewardRangeFromJSON(json: any): RewardRange {
    return RewardRangeFromJSONTyped(json, false);
}

export function RewardRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RewardRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reward': RewardRangeRewardBaseFromJSON(json['reward']),
        'end': json['end'],
    };
}

export function RewardRangeToJSON(value?: RewardRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reward': RewardRangeRewardBaseToJSON(value.reward),
        'end': value.end,
    };
}

