// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserShort } from './UserShort';
import {
    UserShortFromJSON,
    UserShortFromJSONTyped,
    UserShortToJSON,
} from './UserShort';

/**
 * 
 * @export
 * @interface CalendarProfile
 */
export interface CalendarProfile {
    /**
     * 
     * @type {UserShort}
     * @memberof CalendarProfile
     */
    user: UserShort;
    /**
     * 
     * @type {number}
     * @memberof CalendarProfile
     */
    ticketsCount: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarProfile
     */
    ticketsCountTotal: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarProfile
     */
    jackpotChance: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarProfile
     */
    prizeChance: number;
}

/**
 * Check if a given object implements the CalendarProfile interface.
 */
export function instanceOfCalendarProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "ticketsCount" in value;
    isInstance = isInstance && "ticketsCountTotal" in value;
    isInstance = isInstance && "jackpotChance" in value;
    isInstance = isInstance && "prizeChance" in value;

    return isInstance;
}

export function CalendarProfileFromJSON(json: any): CalendarProfile {
    return CalendarProfileFromJSONTyped(json, false);
}

export function CalendarProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserShortFromJSON(json['user']),
        'ticketsCount': json['tickets_count'],
        'ticketsCountTotal': json['tickets_count_total'],
        'jackpotChance': json['jackpot_chance'],
        'prizeChance': json['prize_chance'],
    };
}

export function CalendarProfileToJSON(value?: CalendarProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserShortToJSON(value.user),
        'tickets_count': value.ticketsCount,
        'tickets_count_total': value.ticketsCountTotal,
        'jackpot_chance': value.jackpotChance,
        'prize_chance': value.prizeChance,
    };
}

