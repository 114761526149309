// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrentUser } from './CurrentUser';
import {
    CurrentUserFromJSON,
    CurrentUserFromJSONTyped,
    CurrentUserToJSON,
} from './CurrentUser';
import type { TopUsers } from './TopUsers';
import {
    TopUsersFromJSON,
    TopUsersFromJSONTyped,
    TopUsersToJSON,
} from './TopUsers';

/**
 * 
 * @export
 * @interface Top
 */
export interface Top {
    /**
     * 
     * @type {Array<TopUsers>}
     * @memberof Top
     */
    topUsers: Array<TopUsers>;
    /**
     * 
     * @type {CurrentUser}
     * @memberof Top
     */
    currentUser: CurrentUser;
}

/**
 * Check if a given object implements the Top interface.
 */
export function instanceOfTop(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "topUsers" in value;
    isInstance = isInstance && "currentUser" in value;

    return isInstance;
}

export function TopFromJSON(json: any): Top {
    return TopFromJSONTyped(json, false);
}

export function TopFromJSONTyped(json: any, ignoreDiscriminator: boolean): Top {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topUsers': ((json['top_users'] as Array<any>).map(TopUsersFromJSON)),
        'currentUser': CurrentUserFromJSON(json['current_user']),
    };
}

export function TopToJSON(value?: Top | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'top_users': ((value.topUsers as Array<any>).map(TopUsersToJSON)),
        'current_user': CurrentUserToJSON(value.currentUser),
    };
}

