// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Reward } from './Reward';
import {
    RewardFromJSON,
    RewardFromJSONTyped,
    RewardToJSON,
} from './Reward';
import type { UserShort } from './UserShort';
import {
    UserShortFromJSON,
    UserShortFromJSONTyped,
    UserShortToJSON,
} from './UserShort';

/**
 * 
 * @export
 * @interface ExchangerTopUser
 */
export interface ExchangerTopUser {
    /**
     * 
     * @type {UserShort}
     * @memberof ExchangerTopUser
     */
    readonly user: UserShort | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangerTopUser
     */
    readonly place: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangerTopUser
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerTopUser
     */
    spent: number;
    /**
     * 
     * @type {Date}
     * @memberof ExchangerTopUser
     */
    lastSpent: Date | null;
    /**
     * 
     * @type {Reward}
     * @memberof ExchangerTopUser
     */
    reward: Reward | null;
}

/**
 * Check if a given object implements the ExchangerTopUser interface.
 */
export function instanceOfExchangerTopUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "place" in value;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "spent" in value;
    isInstance = isInstance && "lastSpent" in value;
    isInstance = isInstance && "reward" in value;

    return isInstance;
}

export function ExchangerTopUserFromJSON(json: any): ExchangerTopUser {
    return ExchangerTopUserFromJSONTyped(json, false);
}

export function ExchangerTopUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangerTopUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserShortFromJSON(json['user']),
        'place': json['place'],
        'balance': json['balance'],
        'spent': json['spent'],
        'lastSpent': (json['last_spent'] === null ? null : new Date(json['last_spent'])),
        'reward': RewardFromJSON(json['reward']),
    };
}

export function ExchangerTopUserToJSON(value?: ExchangerTopUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balance': value.balance,
        'spent': value.spent,
        'last_spent': (value.lastSpent === null ? null : value.lastSpent.toISOString()),
        'reward': RewardToJSON(value.reward),
    };
}

