// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PUBGPack
 */
export interface PUBGPack {
    /**
     * 
     * @type {string}
     * @memberof PUBGPack
     */
    img: string;
    /**
     * 
     * @type {number}
     * @memberof PUBGPack
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof PUBGPack
     */
    priceBullcoins: number;
}

/**
 * Check if a given object implements the PUBGPack interface.
 */
export function instanceOfPUBGPack(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "priceBullcoins" in value;

    return isInstance;
}

export function PUBGPackFromJSON(json: any): PUBGPack {
    return PUBGPackFromJSONTyped(json, false);
}

export function PUBGPackFromJSONTyped(json: any, ignoreDiscriminator: boolean): PUBGPack {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'img': json['img'],
        'amount': json['amount'],
        'priceBullcoins': json['price_bullcoins'],
    };
}

export function PUBGPackToJSON(value?: PUBGPack | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'img': value.img,
        'amount': value.amount,
        'price_bullcoins': value.priceBullcoins,
    };
}

