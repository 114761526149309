// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StandoffSubjectQualityEnum } from './StandoffSubjectQualityEnum';
import {
    StandoffSubjectQualityEnumFromJSON,
    StandoffSubjectQualityEnumFromJSONTyped,
    StandoffSubjectQualityEnumToJSON,
} from './StandoffSubjectQualityEnum';
import type { SubjectTag } from './SubjectTag';
import {
    SubjectTagFromJSON,
    SubjectTagFromJSONTyped,
    SubjectTagToJSON,
} from './SubjectTag';

/**
 * 
 * @export
 * @interface StandoffSubject
 */
export interface StandoffSubject {
    /**
     * 
     * @type {number}
     * @memberof StandoffSubject
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof StandoffSubject
     */
    readonly img: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffSubject
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffSubject
     */
    type: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandoffSubject
     */
    price: number;
    /**
     * 
     * @type {Array<SubjectTag>}
     * @memberof StandoffSubject
     */
    readonly tags: Array<SubjectTag>;
    /**
     * 
     * @type {StandoffSubjectQualityEnum}
     * @memberof StandoffSubject
     */
    qualityColor: StandoffSubjectQualityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StandoffSubject
     */
    stattrack: boolean;
}

/**
 * Check if a given object implements the StandoffSubject interface.
 */
export function instanceOfStandoffSubject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "qualityColor" in value;
    isInstance = isInstance && "stattrack" in value;

    return isInstance;
}

export function StandoffSubjectFromJSON(json: any): StandoffSubject {
    return StandoffSubjectFromJSONTyped(json, false);
}

export function StandoffSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandoffSubject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'img': json['img'],
        'name': json['name'],
        'type': json['type'],
        'price': json['price'],
        'tags': ((json['tags'] as Array<any>).map(SubjectTagFromJSON)),
        'qualityColor': StandoffSubjectQualityEnumFromJSON(json['quality_color']),
        'stattrack': json['stattrack'],
    };
}

export function StandoffSubjectToJSON(value?: StandoffSubject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'price': value.price,
        'quality_color': StandoffSubjectQualityEnumToJSON(value.qualityColor),
        'stattrack': value.stattrack,
    };
}

