// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaitOrderStateTyped
 */
export interface WaitOrderStateTyped {
    /**
     * 
     * @type {string}
     * @memberof WaitOrderStateTyped
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof WaitOrderStateTyped
     */
    orderId: string;
}

/**
 * Check if a given object implements the WaitOrderStateTyped interface.
 */
export function instanceOfWaitOrderStateTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "orderId" in value;

    return isInstance;
}

export function WaitOrderStateTypedFromJSON(json: any): WaitOrderStateTyped {
    return WaitOrderStateTypedFromJSONTyped(json, false);
}

export function WaitOrderStateTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaitOrderStateTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'orderId': json['order_id'],
    };
}

export function WaitOrderStateTypedToJSON(value?: WaitOrderStateTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'order_id': value.orderId,
    };
}

