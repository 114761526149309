// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `constant` - Постоянная
 * * `daily` - Ежедневная
 * * `weekly` - Еженедельная
 * @export
 */
export const TaskProgressTypeEnum = {
    Constant: 'constant',
    Daily: 'daily',
    Weekly: 'weekly'
} as const;
export type TaskProgressTypeEnum = typeof TaskProgressTypeEnum[keyof typeof TaskProgressTypeEnum];


export function TaskProgressTypeEnumFromJSON(json: any): TaskProgressTypeEnum {
    return TaskProgressTypeEnumFromJSONTyped(json, false);
}

export function TaskProgressTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskProgressTypeEnum {
    return json as TaskProgressTypeEnum;
}

export function TaskProgressTypeEnumToJSON(value?: TaskProgressTypeEnum | null): any {
    return value as any;
}

