// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlatformEnum } from './PlatformEnum';
import {
    PlatformEnumFromJSON,
    PlatformEnumFromJSONTyped,
    PlatformEnumToJSON,
} from './PlatformEnum';

/**
 * 
 * @export
 * @interface ExchangerSettings
 */
export interface ExchangerSettings {
    /**
     * 
     * @type {Date}
     * @memberof ExchangerSettings
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExchangerSettings
     */
    endDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ExchangerSettings
     */
    rate: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerSettings
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerSettings
     */
    isRewardReady: boolean;
    /**
     * 
     * @type {PlatformEnum}
     * @memberof ExchangerSettings
     */
    platform: PlatformEnum;
    /**
     * 
     * @type {number}
     * @memberof ExchangerSettings
     */
    readonly caseCollectionId: number | null;
}

/**
 * Check if a given object implements the ExchangerSettings interface.
 */
export function instanceOfExchangerSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "isRewardReady" in value;
    isInstance = isInstance && "platform" in value;
    isInstance = isInstance && "caseCollectionId" in value;

    return isInstance;
}

export function ExchangerSettingsFromJSON(json: any): ExchangerSettings {
    return ExchangerSettingsFromJSONTyped(json, false);
}

export function ExchangerSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangerSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'rate': json['rate'],
        'isActive': json['is_active'],
        'isRewardReady': json['is_reward_ready'],
        'platform': PlatformEnumFromJSON(json['platform']),
        'caseCollectionId': json['case_collection_id'],
    };
}

export function ExchangerSettingsToJSON(value?: ExchangerSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': (value.startDate.toISOString()),
        'end_date': (value.endDate.toISOString()),
        'rate': value.rate,
        'is_active': value.isActive,
        'is_reward_ready': value.isRewardReady,
        'platform': PlatformEnumToJSON(value.platform),
    };
}

