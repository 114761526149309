// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Ban
 */
export interface Ban {
    /**
     * 
     * @type {Date}
     * @memberof Ban
     */
    endDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Ban
     */
    reason: string;
    /**
     * 
     * @type {boolean}
     * @memberof Ban
     */
    readonly isForeverBanned: boolean;
}

/**
 * Check if a given object implements the Ban interface.
 */
export function instanceOfBan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "isForeverBanned" in value;

    return isInstance;
}

export function BanFromJSON(json: any): Ban {
    return BanFromJSONTyped(json, false);
}

export function BanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ban {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endDate': (new Date(json['end_date'])),
        'reason': json['reason'],
        'isForeverBanned': json['is_forever_banned'],
    };
}

export function BanToJSON(value?: Ban | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_date': (value.endDate.toISOString()),
        'reason': value.reason,
    };
}

