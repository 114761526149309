// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TakeRewardTickets
 */
export interface TakeRewardTickets {
    /**
     * 
     * @type {number}
     * @memberof TakeRewardTickets
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof TakeRewardTickets
     */
    rewardAmount: number;
}

/**
 * Check if a given object implements the TakeRewardTickets interface.
 */
export function instanceOfTakeRewardTickets(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rewardAmount" in value;

    return isInstance;
}

export function TakeRewardTicketsFromJSON(json: any): TakeRewardTickets {
    return TakeRewardTicketsFromJSONTyped(json, false);
}

export function TakeRewardTicketsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TakeRewardTickets {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rewardAmount': json['reward_amount'],
    };
}

export function TakeRewardTicketsToJSON(value?: TakeRewardTickets | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reward_amount': value.rewardAmount,
    };
}

