// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CS2LootTyped,
    instanceOfCS2LootTyped,
    CS2LootTypedFromJSON,
    CS2LootTypedFromJSONTyped,
    CS2LootTypedToJSON,
} from './CS2LootTyped';
import {
    GenshinLootTyped,
    instanceOfGenshinLootTyped,
    GenshinLootTypedFromJSON,
    GenshinLootTypedFromJSONTyped,
    GenshinLootTypedToJSON,
} from './GenshinLootTyped';
import {
    PUBGLootTyped,
    instanceOfPUBGLootTyped,
    PUBGLootTypedFromJSON,
    PUBGLootTypedFromJSONTyped,
    PUBGLootTypedToJSON,
} from './PUBGLootTyped';
import {
    RobloxLootTyped,
    instanceOfRobloxLootTyped,
    RobloxLootTypedFromJSON,
    RobloxLootTypedFromJSONTyped,
    RobloxLootTypedToJSON,
} from './RobloxLootTyped';
import {
    StandoffLootTyped,
    instanceOfStandoffLootTyped,
    StandoffLootTypedFromJSON,
    StandoffLootTypedFromJSONTyped,
    StandoffLootTypedToJSON,
} from './StandoffLootTyped';

/**
 * @type Loot
 * 
 * @export
 */
export type Loot = { resourcetype: 'CS2Subject' } & CS2LootTyped | { resourcetype: 'GenshinSubject' } & GenshinLootTyped | { resourcetype: 'PUBGSubject' } & PUBGLootTyped | { resourcetype: 'RobloxSubject' } & RobloxLootTyped | { resourcetype: 'StandoffSubject' } & StandoffLootTyped;

export function LootFromJSON(json: any): Loot {
    return LootFromJSONTyped(json, false);
}

export function LootFromJSONTyped(json: any, ignoreDiscriminator: boolean): Loot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'CS2Subject':
            return {...CS2LootTypedFromJSONTyped(json, true), resourcetype: 'CS2Subject'};
        case 'GenshinSubject':
            return {...GenshinLootTypedFromJSONTyped(json, true), resourcetype: 'GenshinSubject'};
        case 'PUBGSubject':
            return {...PUBGLootTypedFromJSONTyped(json, true), resourcetype: 'PUBGSubject'};
        case 'RobloxSubject':
            return {...RobloxLootTypedFromJSONTyped(json, true), resourcetype: 'RobloxSubject'};
        case 'StandoffSubject':
            return {...StandoffLootTypedFromJSONTyped(json, true), resourcetype: 'StandoffSubject'};
        default:
            throw new Error(`No variant of Loot exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function LootToJSON(value?: Loot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'CS2Subject':
            return CS2LootTypedToJSON(value);
        case 'GenshinSubject':
            return GenshinLootTypedToJSON(value);
        case 'PUBGSubject':
            return PUBGLootTypedToJSON(value);
        case 'RobloxSubject':
            return RobloxLootTypedToJSON(value);
        case 'StandoffSubject':
            return StandoffLootTypedToJSON(value);
        default:
            throw new Error(`No variant of Loot exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

