// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AffiseDataRequest,
} from '../models/index';
import {
    AffiseDataRequestFromJSON,
    AffiseDataRequestToJSON,
} from '../models/index';

export interface AffiseApiBindRequest {
    affiseDataRequest: AffiseDataRequest;
}

/**
 * 
 */
export class AffiseApi extends runtime.BaseAPI {

    /**
     */
    async bindRaw(requestParameters: AffiseApiBindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['affiseDataRequest'] == null) {
            throw new runtime.RequiredError(
                'affiseDataRequest',
                'Required parameter "affiseDataRequest" was null or undefined when calling bind().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affise/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AffiseDataRequestToJSON(requestParameters['affiseDataRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async bind(requestParameters: AffiseApiBindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bindRaw(requestParameters, initOverrides);
    }

}
