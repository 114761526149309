// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CS2SubjectQualityColorEnum } from './CS2SubjectQualityColorEnum';
import {
    CS2SubjectQualityColorEnumFromJSON,
    CS2SubjectQualityColorEnumFromJSONTyped,
    CS2SubjectQualityColorEnumToJSON,
} from './CS2SubjectQualityColorEnum';
import type { SubjectTag } from './SubjectTag';
import {
    SubjectTagFromJSON,
    SubjectTagFromJSONTyped,
    SubjectTagToJSON,
} from './SubjectTag';
import type { WearEnum } from './WearEnum';
import {
    WearEnumFromJSON,
    WearEnumFromJSONTyped,
    WearEnumToJSON,
} from './WearEnum';

/**
 * 
 * @export
 * @interface CS2SubjectTyped
 */
export interface CS2SubjectTyped {
    /**
     * 
     * @type {number}
     * @memberof CS2SubjectTyped
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof CS2SubjectTyped
     */
    readonly img: string;
    /**
     * 
     * @type {string}
     * @memberof CS2SubjectTyped
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CS2SubjectTyped
     */
    type: string | null;
    /**
     * 
     * @type {number}
     * @memberof CS2SubjectTyped
     */
    price: number;
    /**
     * 
     * @type {Array<SubjectTag>}
     * @memberof CS2SubjectTyped
     */
    readonly tags: Array<SubjectTag>;
    /**
     * 
     * @type {CS2SubjectQualityColorEnum}
     * @memberof CS2SubjectTyped
     */
    qualityColor: CS2SubjectQualityColorEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CS2SubjectTyped
     */
    stattrack: boolean;
    /**
     * 
     * @type {WearEnum}
     * @memberof CS2SubjectTyped
     */
    wear: WearEnum | null;
    /**
     * 
     * @type {number}
     * @memberof CS2SubjectTyped
     */
    skinCount: number;
    /**
     * 
     * @type {string}
     * @memberof CS2SubjectTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the CS2SubjectTyped interface.
 */
export function instanceOfCS2SubjectTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "qualityColor" in value;
    isInstance = isInstance && "stattrack" in value;
    isInstance = isInstance && "wear" in value;
    isInstance = isInstance && "skinCount" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function CS2SubjectTypedFromJSON(json: any): CS2SubjectTyped {
    return CS2SubjectTypedFromJSONTyped(json, false);
}

export function CS2SubjectTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CS2SubjectTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'img': json['img'],
        'name': json['name'],
        'type': json['type'],
        'price': json['price'],
        'tags': ((json['tags'] as Array<any>).map(SubjectTagFromJSON)),
        'qualityColor': CS2SubjectQualityColorEnumFromJSON(json['quality_color']),
        'stattrack': json['stattrack'],
        'wear': WearEnumFromJSON(json['wear']),
        'skinCount': json['skin_count'],
        'resourcetype': json['resourcetype'],
    };
}

export function CS2SubjectTypedToJSON(value?: CS2SubjectTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'price': value.price,
        'quality_color': CS2SubjectQualityColorEnumToJSON(value.qualityColor),
        'stattrack': value.stattrack,
        'wear': WearEnumToJSON(value.wear),
        'skin_count': value.skinCount,
        'resourcetype': value.resourcetype,
    };
}

