// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarLevel
 */
export interface CalendarLevel {
    /**
     * 
     * @type {number}
     * @memberof CalendarLevel
     */
    number: number | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarLevel
     */
    rewardIcon: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarLevel
     */
    rewardName: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarLevel
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarLevel
     */
    readonly rewardType: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarLevel
     */
    rewardDescription: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarLevel
     */
    rewardQuality: string;
}

/**
 * Check if a given object implements the CalendarLevel interface.
 */
export function instanceOfCalendarLevel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "rewardIcon" in value;
    isInstance = isInstance && "rewardName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rewardType" in value;
    isInstance = isInstance && "rewardDescription" in value;
    isInstance = isInstance && "rewardQuality" in value;

    return isInstance;
}

export function CalendarLevelFromJSON(json: any): CalendarLevel {
    return CalendarLevelFromJSONTyped(json, false);
}

export function CalendarLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'rewardIcon': json['reward_icon'],
        'rewardName': json['reward_name'],
        'id': json['id'],
        'rewardType': json['reward_type'],
        'rewardDescription': json['reward_description'],
        'rewardQuality': json['reward_quality'],
    };
}

export function CalendarLevelToJSON(value?: CalendarLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'reward_icon': value.rewardIcon,
        'reward_name': value.rewardName,
        'reward_description': value.rewardDescription,
        'reward_quality': value.rewardQuality,
    };
}

