// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Inventory } from './Inventory';
import {
    InventoryFromJSON,
    InventoryFromJSONTyped,
    InventoryToJSON,
} from './Inventory';

/**
 * 
 * @export
 * @interface OpenCaseBatchResponse
 */
export interface OpenCaseBatchResponse {
    /**
     * 
     * @type {Array<Inventory>}
     * @memberof OpenCaseBatchResponse
     */
    items: Array<Inventory>;
    /**
     * 
     * @type {number}
     * @memberof OpenCaseBatchResponse
     */
    cashback: number;
    /**
     * 
     * @type {number}
     * @memberof OpenCaseBatchResponse
     */
    doubloonsCount: number;
}

/**
 * Check if a given object implements the OpenCaseBatchResponse interface.
 */
export function instanceOfOpenCaseBatchResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "cashback" in value;
    isInstance = isInstance && "doubloonsCount" in value;

    return isInstance;
}

export function OpenCaseBatchResponseFromJSON(json: any): OpenCaseBatchResponse {
    return OpenCaseBatchResponseFromJSONTyped(json, false);
}

export function OpenCaseBatchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenCaseBatchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(InventoryFromJSON)),
        'cashback': json['cashback'],
        'doubloonsCount': json['doubloons_count'],
    };
}

export function OpenCaseBatchResponseToJSON(value?: OpenCaseBatchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(InventoryToJSON)),
        'cashback': value.cashback,
        'doubloons_count': value.doubloonsCount,
    };
}

