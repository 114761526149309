// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentsTarget } from './PaymentsTarget';
import {
    PaymentsTargetFromJSON,
    PaymentsTargetFromJSONTyped,
    PaymentsTargetToJSON,
} from './PaymentsTarget';

/**
 * 
 * @export
 * @interface WithdrawalRestrictions
 */
export interface WithdrawalRestrictions {
    /**
     * 
     * @type {PaymentsTarget}
     * @memberof WithdrawalRestrictions
     */
    readonly totalPayments: PaymentsTarget;
    /**
     * 
     * @type {PaymentsTarget}
     * @memberof WithdrawalRestrictions
     */
    readonly last2WeeksPayments: PaymentsTarget;
}

/**
 * Check if a given object implements the WithdrawalRestrictions interface.
 */
export function instanceOfWithdrawalRestrictions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalPayments" in value;
    isInstance = isInstance && "last2WeeksPayments" in value;

    return isInstance;
}

export function WithdrawalRestrictionsFromJSON(json: any): WithdrawalRestrictions {
    return WithdrawalRestrictionsFromJSONTyped(json, false);
}

export function WithdrawalRestrictionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalRestrictions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPayments': PaymentsTargetFromJSON(json['total_payments']),
        'last2WeeksPayments': PaymentsTargetFromJSON(json['last_2_weeks_payments']),
    };
}

export function WithdrawalRestrictionsToJSON(value?: WithdrawalRestrictions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

