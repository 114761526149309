// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NextStepResponseStatusEnum } from './NextStepResponseStatusEnum';
import {
    NextStepResponseStatusEnumFromJSON,
    NextStepResponseStatusEnumFromJSONTyped,
    NextStepResponseStatusEnumToJSON,
} from './NextStepResponseStatusEnum';

/**
 * 
 * @export
 * @interface NextStepResponse
 */
export interface NextStepResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof NextStepResponse
     */
    minesLocations: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof NextStepResponse
     */
    openedLocations: Array<number>;
    /**
     * 
     * @type {NextStepResponseStatusEnum}
     * @memberof NextStepResponse
     */
    status: NextStepResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof NextStepResponse
     */
    rate: number;
    /**
     * 
     * @type {number}
     * @memberof NextStepResponse
     */
    cellNumber: number;
    /**
     * 
     * @type {boolean}
     * @memberof NextStepResponse
     */
    isWin: boolean;
}

/**
 * Check if a given object implements the NextStepResponse interface.
 */
export function instanceOfNextStepResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minesLocations" in value;
    isInstance = isInstance && "openedLocations" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "cellNumber" in value;
    isInstance = isInstance && "isWin" in value;

    return isInstance;
}

export function NextStepResponseFromJSON(json: any): NextStepResponse {
    return NextStepResponseFromJSONTyped(json, false);
}

export function NextStepResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextStepResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minesLocations': json['mines_locations'],
        'openedLocations': json['opened_locations'],
        'status': NextStepResponseStatusEnumFromJSON(json['status']),
        'rate': json['rate'],
        'cellNumber': json['cell_number'],
        'isWin': json['is_win'],
    };
}

export function NextStepResponseToJSON(value?: NextStepResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mines_locations': value.minesLocations,
        'opened_locations': value.openedLocations,
        'status': NextStepResponseStatusEnumToJSON(value.status),
        'rate': value.rate,
        'cell_number': value.cellNumber,
        'is_win': value.isWin,
    };
}

