export const getLocale = (url: string) => {
  const match1 = url.match(/^\/(ru|en|uz)$/);
  const match2 = url.match(/^\/(ru|en|uz)\?.*/);
  const match3 = url.match(/^\/(ru|en|uz)\/.*/);
  if (match1) {
    return match1[1];
  } else if (match2) {
    return match2[1];
  } else if (match3) {
    return match3[1];
  } else {
    return null;
  }
};
