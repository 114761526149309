// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Question } from './Question';
import {
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';

/**
 * 
 * @export
 * @interface QuestionSection
 */
export interface QuestionSection {
    /**
     * 
     * @type {string}
     * @memberof QuestionSection
     */
    name: string;
    /**
     * 
     * @type {Array<Question>}
     * @memberof QuestionSection
     */
    questions: Array<Question>;
    /**
     * 
     * @type {string}
     * @memberof QuestionSection
     */
    slug: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionSection
     */
    picture: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionSection
     */
    subTitle: string | null;
}

/**
 * Check if a given object implements the QuestionSection interface.
 */
export function instanceOfQuestionSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "questions" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "picture" in value;
    isInstance = isInstance && "subTitle" in value;

    return isInstance;
}

export function QuestionSectionFromJSON(json: any): QuestionSection {
    return QuestionSectionFromJSONTyped(json, false);
}

export function QuestionSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'questions': ((json['questions'] as Array<any>).map(QuestionFromJSON)),
        'slug': json['slug'],
        'picture': json['picture'],
        'subTitle': json['sub_title'],
    };
}

export function QuestionSectionToJSON(value?: QuestionSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'questions': ((value.questions as Array<any>).map(QuestionToJSON)),
        'slug': value.slug,
        'picture': value.picture,
        'sub_title': value.subTitle,
    };
}

