// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthUrl,
  SocialAuthRequest,
  SocialAuthResponse,
} from '../models/index';
import {
    AuthUrlFromJSON,
    AuthUrlToJSON,
    SocialAuthRequestFromJSON,
    SocialAuthRequestToJSON,
    SocialAuthResponseFromJSON,
    SocialAuthResponseToJSON,
} from '../models/index';

export interface SocialApiFacebookAuthCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiFacebookUrlRetrieveRequest {
    redirectUri: string;
}

export interface SocialApiGoogleAuthCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiGoogleUrlRetrieveRequest {
    redirectUri: string;
}

export interface SocialApiSteamAuthCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiSteamBindCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiSteamUrlRetrieveRequest {
    redirectUri: string;
}

export interface SocialApiTelegramAuthCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiTelegramBindCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiTelegramUrlRetrieveRequest {
    redirectUri: string;
}

export interface SocialApiVkAuthCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiVkBindCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiVkIdAuthCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiVkIdBindCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiVkIdUrlRetrieveRequest {
    redirectUri: string;
}

export interface SocialApiVkUrlRetrieveRequest {
    redirectUri: string;
}

export interface SocialApiYandexAuthCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiYandexBindCreateRequest {
    socialAuthRequest: SocialAuthRequest;
}

export interface SocialApiYandexUrlRetrieveRequest {
    redirectUri: string;
}

/**
 * 
 */
export class SocialApi extends runtime.BaseAPI {

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async facebookAuthCreateRaw(requestParameters: SocialApiFacebookAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialAuthResponse>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling facebookAuthCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/facebook/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialAuthResponseFromJSON(jsonValue));
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async facebookAuthCreate(requestParameters: SocialApiFacebookAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialAuthResponse> {
        const response = await this.facebookAuthCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async facebookUnbindCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/facebook/unbind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async facebookUnbindCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.facebookUnbindCreateRaw(initOverrides);
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async facebookUrlRetrieveRaw(requestParameters: SocialApiFacebookUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUrl>> {
        if (requestParameters['redirectUri'] == null) {
            throw new runtime.RequiredError(
                'redirectUri',
                'Required parameter "redirectUri" was null or undefined when calling facebookUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['redirectUri'] != null) {
            queryParameters['redirect_uri'] = requestParameters['redirectUri'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/facebook/url/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUrlFromJSON(jsonValue));
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async facebookUrlRetrieve(requestParameters: SocialApiFacebookUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUrl> {
        const response = await this.facebookUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async googleAuthCreateRaw(requestParameters: SocialApiGoogleAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialAuthResponse>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling googleAuthCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/google/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialAuthResponseFromJSON(jsonValue));
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async googleAuthCreate(requestParameters: SocialApiGoogleAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialAuthResponse> {
        const response = await this.googleAuthCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async googleUrlRetrieveRaw(requestParameters: SocialApiGoogleUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUrl>> {
        if (requestParameters['redirectUri'] == null) {
            throw new runtime.RequiredError(
                'redirectUri',
                'Required parameter "redirectUri" was null or undefined when calling googleUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['redirectUri'] != null) {
            queryParameters['redirect_uri'] = requestParameters['redirectUri'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/google/url/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUrlFromJSON(jsonValue));
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async googleUrlRetrieve(requestParameters: SocialApiGoogleUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUrl> {
        const response = await this.googleUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async steamAuthCreateRaw(requestParameters: SocialApiSteamAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialAuthResponse>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling steamAuthCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/steam/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialAuthResponseFromJSON(jsonValue));
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async steamAuthCreate(requestParameters: SocialApiSteamAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialAuthResponse> {
        const response = await this.steamAuthCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async steamBindCreateRaw(requestParameters: SocialApiSteamBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling steamBindCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/steam/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async steamBindCreate(requestParameters: SocialApiSteamBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.steamBindCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async steamUnbindCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/steam/unbind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async steamUnbindCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.steamUnbindCreateRaw(initOverrides);
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async steamUrlRetrieveRaw(requestParameters: SocialApiSteamUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUrl>> {
        if (requestParameters['redirectUri'] == null) {
            throw new runtime.RequiredError(
                'redirectUri',
                'Required parameter "redirectUri" was null or undefined when calling steamUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['redirectUri'] != null) {
            queryParameters['redirect_uri'] = requestParameters['redirectUri'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/steam/url/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUrlFromJSON(jsonValue));
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async steamUrlRetrieve(requestParameters: SocialApiSteamUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUrl> {
        const response = await this.steamUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async telegramAuthCreateRaw(requestParameters: SocialApiTelegramAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialAuthResponse>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling telegramAuthCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/telegram/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialAuthResponseFromJSON(jsonValue));
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async telegramAuthCreate(requestParameters: SocialApiTelegramAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialAuthResponse> {
        const response = await this.telegramAuthCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async telegramBindCreateRaw(requestParameters: SocialApiTelegramBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling telegramBindCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/telegram/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async telegramBindCreate(requestParameters: SocialApiTelegramBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.telegramBindCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async telegramUnbindCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/telegram/unbind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async telegramUnbindCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.telegramUnbindCreateRaw(initOverrides);
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async telegramUrlRetrieveRaw(requestParameters: SocialApiTelegramUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUrl>> {
        if (requestParameters['redirectUri'] == null) {
            throw new runtime.RequiredError(
                'redirectUri',
                'Required parameter "redirectUri" was null or undefined when calling telegramUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['redirectUri'] != null) {
            queryParameters['redirect_uri'] = requestParameters['redirectUri'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/telegram/url/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUrlFromJSON(jsonValue));
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async telegramUrlRetrieve(requestParameters: SocialApiTelegramUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUrl> {
        const response = await this.telegramUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async vkAuthCreateRaw(requestParameters: SocialApiVkAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialAuthResponse>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling vkAuthCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialAuthResponseFromJSON(jsonValue));
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async vkAuthCreate(requestParameters: SocialApiVkAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialAuthResponse> {
        const response = await this.vkAuthCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async vkBindCreateRaw(requestParameters: SocialApiVkBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling vkBindCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async vkBindCreate(requestParameters: SocialApiVkBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vkBindCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async vkIdAuthCreateRaw(requestParameters: SocialApiVkIdAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialAuthResponse>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling vkIdAuthCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk_id/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialAuthResponseFromJSON(jsonValue));
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async vkIdAuthCreate(requestParameters: SocialApiVkIdAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialAuthResponse> {
        const response = await this.vkIdAuthCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async vkIdBindCreateRaw(requestParameters: SocialApiVkIdBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling vkIdBindCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk_id/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async vkIdBindCreate(requestParameters: SocialApiVkIdBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vkIdBindCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async vkIdUnbindCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk_id/unbind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async vkIdUnbindCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vkIdUnbindCreateRaw(initOverrides);
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async vkIdUrlRetrieveRaw(requestParameters: SocialApiVkIdUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUrl>> {
        if (requestParameters['redirectUri'] == null) {
            throw new runtime.RequiredError(
                'redirectUri',
                'Required parameter "redirectUri" was null or undefined when calling vkIdUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['redirectUri'] != null) {
            queryParameters['redirect_uri'] = requestParameters['redirectUri'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk_id/url/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUrlFromJSON(jsonValue));
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async vkIdUrlRetrieve(requestParameters: SocialApiVkIdUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUrl> {
        const response = await this.vkIdUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async vkUnbindCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk/unbind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async vkUnbindCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vkUnbindCreateRaw(initOverrides);
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async vkUrlRetrieveRaw(requestParameters: SocialApiVkUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUrl>> {
        if (requestParameters['redirectUri'] == null) {
            throw new runtime.RequiredError(
                'redirectUri',
                'Required parameter "redirectUri" was null or undefined when calling vkUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['redirectUri'] != null) {
            queryParameters['redirect_uri'] = requestParameters['redirectUri'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/vk/url/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUrlFromJSON(jsonValue));
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async vkUrlRetrieve(requestParameters: SocialApiVkUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUrl> {
        const response = await this.vkUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async yandexAuthCreateRaw(requestParameters: SocialApiYandexAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialAuthResponse>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling yandexAuthCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/yandex/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialAuthResponseFromJSON(jsonValue));
    }

    /**
     * Авторизовывает пользователя по его профилю в социальной сети
     */
    async yandexAuthCreate(requestParameters: SocialApiYandexAuthCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialAuthResponse> {
        const response = await this.yandexAuthCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async yandexBindCreateRaw(requestParameters: SocialApiYandexBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['socialAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'socialAuthRequest',
                'Required parameter "socialAuthRequest" was null or undefined when calling yandexBindCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/yandex/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialAuthRequestToJSON(requestParameters['socialAuthRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Привязывает к авторизованному пользователю его профиль в социальной сети
     */
    async yandexBindCreate(requestParameters: SocialApiYandexBindCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.yandexBindCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async yandexUnbindCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/yandex/unbind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отвязывает пользователя от его профиля в социальной сети
     */
    async yandexUnbindCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.yandexUnbindCreateRaw(initOverrides);
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async yandexUrlRetrieveRaw(requestParameters: SocialApiYandexUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUrl>> {
        if (requestParameters['redirectUri'] == null) {
            throw new runtime.RequiredError(
                'redirectUri',
                'Required parameter "redirectUri" was null or undefined when calling yandexUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['redirectUri'] != null) {
            queryParameters['redirect_uri'] = requestParameters['redirectUri'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/social/yandex/url/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUrlFromJSON(jsonValue));
    }

    /**
     * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
     */
    async yandexUrlRetrieve(requestParameters: SocialApiYandexUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUrl> {
        const response = await this.yandexUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
