// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ArtifactRewardTyped,
    instanceOfArtifactRewardTyped,
    ArtifactRewardTypedFromJSON,
    ArtifactRewardTypedFromJSONTyped,
    ArtifactRewardTypedToJSON,
} from './ArtifactRewardTyped';
import {
    AvatarBorderTyped,
    instanceOfAvatarBorderTyped,
    AvatarBorderTypedFromJSON,
    AvatarBorderTypedFromJSONTyped,
    AvatarBorderTypedToJSON,
} from './AvatarBorderTyped';
import {
    GoldRewardTyped,
    instanceOfGoldRewardTyped,
    GoldRewardTypedFromJSON,
    GoldRewardTypedFromJSONTyped,
    GoldRewardTypedToJSON,
} from './GoldRewardTyped';
import {
    RewardBaseTyped,
    instanceOfRewardBaseTyped,
    RewardBaseTypedFromJSON,
    RewardBaseTypedFromJSONTyped,
    RewardBaseTypedToJSON,
} from './RewardBaseTyped';
import {
    SimpleCaseRewardTyped,
    instanceOfSimpleCaseRewardTyped,
    SimpleCaseRewardTypedFromJSON,
    SimpleCaseRewardTypedFromJSONTyped,
    SimpleCaseRewardTypedToJSON,
} from './SimpleCaseRewardTyped';
import {
    SimpleResponseTyped,
    instanceOfSimpleResponseTyped,
    SimpleResponseTypedFromJSON,
    SimpleResponseTypedFromJSONTyped,
    SimpleResponseTypedToJSON,
} from './SimpleResponseTyped';
import {
    SubjectInventoryTyped,
    instanceOfSubjectInventoryTyped,
    SubjectInventoryTypedFromJSON,
    SubjectInventoryTypedFromJSONTyped,
    SubjectInventoryTypedToJSON,
} from './SubjectInventoryTyped';
import {
    SubjectRewardTyped,
    instanceOfSubjectRewardTyped,
    SubjectRewardTypedFromJSON,
    SubjectRewardTypedFromJSONTyped,
    SubjectRewardTypedToJSON,
} from './SubjectRewardTyped';

/**
 * @type Reward
 * 
 * @export
 */
export type Reward = { resourcetype: 'ArtifactReward' } & ArtifactRewardTyped | { resourcetype: 'AvatarBorderReward' } & AvatarBorderTyped | { resourcetype: 'FreeCaseOpenReward' } & SimpleCaseRewardTyped | { resourcetype: 'GoldReward' } & GoldRewardTyped | { resourcetype: 'NicknameDecorationReward' } & RewardBaseTyped | { resourcetype: 'PromoCodePercentReward' } & SimpleResponseTyped | { resourcetype: 'SkinReward' } & SubjectInventoryTyped | { resourcetype: 'SockWithSubjectReward' } & SubjectRewardTyped | { resourcetype: 'StickerReward' } & SubjectInventoryTyped | { resourcetype: 'SubjectWithChanceReward' } & SubjectInventoryTyped | { resourcetype: 'TicketsReward' } & SimpleResponseTyped;

export function RewardFromJSON(json: any): Reward {
    return RewardFromJSONTyped(json, false);
}

export function RewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'ArtifactReward':
            return {...ArtifactRewardTypedFromJSONTyped(json, true), resourcetype: 'ArtifactReward'};
        case 'AvatarBorderReward':
            return {...AvatarBorderTypedFromJSONTyped(json, true), resourcetype: 'AvatarBorderReward'};
        case 'FreeCaseOpenReward':
            return {...SimpleCaseRewardTypedFromJSONTyped(json, true), resourcetype: 'FreeCaseOpenReward'};
        case 'GoldReward':
            return {...GoldRewardTypedFromJSONTyped(json, true), resourcetype: 'GoldReward'};
        case 'NicknameDecorationReward':
            return {...RewardBaseTypedFromJSONTyped(json, true), resourcetype: 'NicknameDecorationReward'};
        case 'PromoCodePercentReward':
            return {...SimpleResponseTypedFromJSONTyped(json, true), resourcetype: 'PromoCodePercentReward'};
        case 'SkinReward':
            return {...SubjectInventoryTypedFromJSONTyped(json, true), resourcetype: 'SkinReward'};
        case 'SockWithSubjectReward':
            return {...SubjectRewardTypedFromJSONTyped(json, true), resourcetype: 'SockWithSubjectReward'};
        case 'StickerReward':
            return {...SubjectInventoryTypedFromJSONTyped(json, true), resourcetype: 'StickerReward'};
        case 'SubjectWithChanceReward':
            return {...SubjectInventoryTypedFromJSONTyped(json, true), resourcetype: 'SubjectWithChanceReward'};
        case 'TicketsReward':
            return {...SimpleResponseTypedFromJSONTyped(json, true), resourcetype: 'TicketsReward'};
        default:
            throw new Error(`No variant of Reward exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function RewardToJSON(value?: Reward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'ArtifactReward':
            return ArtifactRewardTypedToJSON(value);
        case 'AvatarBorderReward':
            return AvatarBorderTypedToJSON(value);
        case 'FreeCaseOpenReward':
            return SimpleCaseRewardTypedToJSON(value);
        case 'GoldReward':
            return GoldRewardTypedToJSON(value);
        case 'NicknameDecorationReward':
            return RewardBaseTypedToJSON(value);
        case 'PromoCodePercentReward':
            return SimpleResponseTypedToJSON(value);
        case 'SkinReward':
            return SubjectInventoryTypedToJSON(value);
        case 'SockWithSubjectReward':
            return SubjectRewardTypedToJSON(value);
        case 'StickerReward':
            return SubjectInventoryTypedToJSON(value);
        case 'SubjectWithChanceReward':
            return SubjectInventoryTypedToJSON(value);
        case 'TicketsReward':
            return SimpleResponseTypedToJSON(value);
        default:
            throw new Error(`No variant of Reward exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

