// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StandoffWithdrawalRead } from './StandoffWithdrawalRead';
import {
    StandoffWithdrawalReadFromJSON,
    StandoffWithdrawalReadFromJSONTyped,
    StandoffWithdrawalReadToJSON,
} from './StandoffWithdrawalRead';

/**
 * 
 * @export
 * @interface PaginatedStandoffWithdrawalReadList
 */
export interface PaginatedStandoffWithdrawalReadList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedStandoffWithdrawalReadList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedStandoffWithdrawalReadList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedStandoffWithdrawalReadList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<StandoffWithdrawalRead>}
     * @memberof PaginatedStandoffWithdrawalReadList
     */
    results: Array<StandoffWithdrawalRead>;
}

/**
 * Check if a given object implements the PaginatedStandoffWithdrawalReadList interface.
 */
export function instanceOfPaginatedStandoffWithdrawalReadList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function PaginatedStandoffWithdrawalReadListFromJSON(json: any): PaginatedStandoffWithdrawalReadList {
    return PaginatedStandoffWithdrawalReadListFromJSONTyped(json, false);
}

export function PaginatedStandoffWithdrawalReadListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedStandoffWithdrawalReadList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(StandoffWithdrawalReadFromJSON)),
    };
}

export function PaginatedStandoffWithdrawalReadListToJSON(value?: PaginatedStandoffWithdrawalReadList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(StandoffWithdrawalReadToJSON)),
    };
}

