// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLevel
 */
export interface UserLevel {
    /**
     * 
     * @type {boolean}
     * @memberof UserLevel
     */
    isBonusActivated: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserLevel
     */
    doubloons: number;
}

/**
 * Check if a given object implements the UserLevel interface.
 */
export function instanceOfUserLevel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isBonusActivated" in value;
    isInstance = isInstance && "doubloons" in value;

    return isInstance;
}

export function UserLevelFromJSON(json: any): UserLevel {
    return UserLevelFromJSONTyped(json, false);
}

export function UserLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isBonusActivated': json['is_bonus_activated'],
        'doubloons': json['doubloons'],
    };
}

export function UserLevelToJSON(value?: UserLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_bonus_activated': value.isBonusActivated,
        'doubloons': value.doubloons,
    };
}

