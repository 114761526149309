// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserShort } from './UserShort';
import {
    UserShortFromJSON,
    UserShortFromJSONTyped,
    UserShortToJSON,
} from './UserShort';

/**
 * 
 * @export
 * @interface CoinRoom
 */
export interface CoinRoom {
    /**
     * 
     * @type {number}
     * @memberof CoinRoom
     */
    readonly id: number;
    /**
     * 
     * @type {UserShort}
     * @memberof CoinRoom
     */
    readonly host: UserShort;
    /**
     * 
     * @type {number}
     * @memberof CoinRoom
     */
    readonly visibleId: number;
    /**
     * 
     * @type {number}
     * @memberof CoinRoom
     */
    readonly numParticipants: number;
    /**
     * 
     * @type {number}
     * @memberof CoinRoom
     */
    totalSum: number;
}

/**
 * Check if a given object implements the CoinRoom interface.
 */
export function instanceOfCoinRoom(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "host" in value;
    isInstance = isInstance && "visibleId" in value;
    isInstance = isInstance && "numParticipants" in value;
    isInstance = isInstance && "totalSum" in value;

    return isInstance;
}

export function CoinRoomFromJSON(json: any): CoinRoom {
    return CoinRoomFromJSONTyped(json, false);
}

export function CoinRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoinRoom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'host': UserShortFromJSON(json['host']),
        'visibleId': json['visible_id'],
        'numParticipants': json['num_participants'],
        'totalSum': json['total_sum'],
    };
}

export function CoinRoomToJSON(value?: CoinRoom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_sum': value.totalSum,
    };
}

