// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentsTarget
 */
export interface PaymentsTarget {
    /**
     * 
     * @type {number}
     * @memberof PaymentsTarget
     */
    target: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsTarget
     */
    passed: boolean;
}

/**
 * Check if a given object implements the PaymentsTarget interface.
 */
export function instanceOfPaymentsTarget(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "passed" in value;

    return isInstance;
}

export function PaymentsTargetFromJSON(json: any): PaymentsTarget {
    return PaymentsTargetFromJSONTyped(json, false);
}

export function PaymentsTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentsTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target': json['target'],
        'passed': json['passed'],
    };
}

export function PaymentsTargetToJSON(value?: PaymentsTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target': value.target,
        'passed': value.passed,
    };
}

