// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AffiseDataRequest
 */
export interface AffiseDataRequest {
    /**
     * 
     * @type {number}
     * @memberof AffiseDataRequest
     */
    offerId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiseDataRequest
     */
    partnerId: number;
    /**
     * 
     * @type {string}
     * @memberof AffiseDataRequest
     */
    clickId?: string;
}

/**
 * Check if a given object implements the AffiseDataRequest interface.
 */
export function instanceOfAffiseDataRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "offerId" in value;
    isInstance = isInstance && "partnerId" in value;

    return isInstance;
}

export function AffiseDataRequestFromJSON(json: any): AffiseDataRequest {
    return AffiseDataRequestFromJSONTyped(json, false);
}

export function AffiseDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiseDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offerId': json['offer_id'],
        'partnerId': json['partner_id'],
        'clickId': !exists(json, 'click_id') ? undefined : json['click_id'],
    };
}

export function AffiseDataRequestToJSON(value?: AffiseDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offer_id': value.offerId,
        'partner_id': value.partnerId,
        'click_id': value.clickId,
    };
}

