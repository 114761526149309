// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseListsByCollections } from './CaseListsByCollections';
import {
    CaseListsByCollectionsFromJSON,
    CaseListsByCollectionsFromJSONTyped,
    CaseListsByCollectionsToJSON,
} from './CaseListsByCollections';

/**
 * 
 * @export
 * @interface PaginatedCaseListsByCollectionsList
 */
export interface PaginatedCaseListsByCollectionsList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedCaseListsByCollectionsList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedCaseListsByCollectionsList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedCaseListsByCollectionsList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<CaseListsByCollections>}
     * @memberof PaginatedCaseListsByCollectionsList
     */
    results: Array<CaseListsByCollections>;
}

/**
 * Check if a given object implements the PaginatedCaseListsByCollectionsList interface.
 */
export function instanceOfPaginatedCaseListsByCollectionsList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function PaginatedCaseListsByCollectionsListFromJSON(json: any): PaginatedCaseListsByCollectionsList {
    return PaginatedCaseListsByCollectionsListFromJSONTyped(json, false);
}

export function PaginatedCaseListsByCollectionsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedCaseListsByCollectionsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(CaseListsByCollectionsFromJSON)),
    };
}

export function PaginatedCaseListsByCollectionsListToJSON(value?: PaginatedCaseListsByCollectionsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(CaseListsByCollectionsToJSON)),
    };
}

