// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinerGameStartRequest
 */
export interface MinerGameStartRequest {
    /**
     * 
     * @type {number}
     * @memberof MinerGameStartRequest
     */
    startRate: number;
    /**
     * 
     * @type {number}
     * @memberof MinerGameStartRequest
     */
    numberMines: number;
}

/**
 * Check if a given object implements the MinerGameStartRequest interface.
 */
export function instanceOfMinerGameStartRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startRate" in value;
    isInstance = isInstance && "numberMines" in value;

    return isInstance;
}

export function MinerGameStartRequestFromJSON(json: any): MinerGameStartRequest {
    return MinerGameStartRequestFromJSONTyped(json, false);
}

export function MinerGameStartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinerGameStartRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startRate': json['start_rate'],
        'numberMines': json['number_mines'],
    };
}

export function MinerGameStartRequestToJSON(value?: MinerGameStartRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_rate': value.startRate,
        'number_mines': value.numberMines,
    };
}

