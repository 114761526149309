// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WithdrawalSettings
 */
export interface WithdrawalSettings {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalSettings
     */
    skinName: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalSettings
     */
    skinPicture: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalSettings
     */
    isSurplusEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalSettings
     */
    marketFee: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalSettings
     */
    rate: number;
}

/**
 * Check if a given object implements the WithdrawalSettings interface.
 */
export function instanceOfWithdrawalSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "skinName" in value;
    isInstance = isInstance && "skinPicture" in value;
    isInstance = isInstance && "isSurplusEnabled" in value;
    isInstance = isInstance && "marketFee" in value;
    isInstance = isInstance && "rate" in value;

    return isInstance;
}

export function WithdrawalSettingsFromJSON(json: any): WithdrawalSettings {
    return WithdrawalSettingsFromJSONTyped(json, false);
}

export function WithdrawalSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skinName': json['skin_name'],
        'skinPicture': json['skin_picture'],
        'isSurplusEnabled': json['is_surplus_enabled'],
        'marketFee': json['market_fee'],
        'rate': json['rate'],
    };
}

export function WithdrawalSettingsToJSON(value?: WithdrawalSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skin_name': value.skinName,
        'skin_picture': value.skinPicture,
        'is_surplus_enabled': value.isSurplusEnabled,
        'market_fee': value.marketFee,
        'rate': value.rate,
    };
}

