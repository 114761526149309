// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenCaseBatchRequest
 */
export interface OpenCaseBatchRequest {
    /**
     * 
     * @type {number}
     * @memberof OpenCaseBatchRequest
     */
    idCase: number;
    /**
     * 
     * @type {number}
     * @memberof OpenCaseBatchRequest
     */
    k: number;
}

/**
 * Check if a given object implements the OpenCaseBatchRequest interface.
 */
export function instanceOfOpenCaseBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "idCase" in value;
    isInstance = isInstance && "k" in value;

    return isInstance;
}

export function OpenCaseBatchRequestFromJSON(json: any): OpenCaseBatchRequest {
    return OpenCaseBatchRequestFromJSONTyped(json, false);
}

export function OpenCaseBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenCaseBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idCase': json['id_case'],
        'k': json['k'],
    };
}

export function OpenCaseBatchRequestToJSON(value?: OpenCaseBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id_case': value.idCase,
        'k': value.k,
    };
}

