// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinerGameSettings
 */
export interface MinerGameSettings {
    /**
     * 
     * @type {number}
     * @memberof MinerGameSettings
     */
    maxBet: number | null;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof MinerGameSettings
     */
    coefficients: Array<Array<number>>;
}

/**
 * Check if a given object implements the MinerGameSettings interface.
 */
export function instanceOfMinerGameSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maxBet" in value;
    isInstance = isInstance && "coefficients" in value;

    return isInstance;
}

export function MinerGameSettingsFromJSON(json: any): MinerGameSettings {
    return MinerGameSettingsFromJSONTyped(json, false);
}

export function MinerGameSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinerGameSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxBet': json['max_bet'],
        'coefficients': json['coefficients'],
    };
}

export function MinerGameSettingsToJSON(value?: MinerGameSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max_bet': value.maxBet,
        'coefficients': value.coefficients,
    };
}

