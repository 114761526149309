// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserShort } from './UserShort';
import {
    UserShortFromJSON,
    UserShortFromJSONTyped,
    UserShortToJSON,
} from './UserShort';

/**
 * 
 * @export
 * @interface GameResultOutput
 */
export interface GameResultOutput {
    /**
     * 
     * @type {string}
     * @memberof GameResultOutput
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GameResultOutput
     */
    rate: number;
    /**
     * 
     * @type {number}
     * @memberof GameResultOutput
     */
    income: number;
    /**
     * 
     * @type {UserShort}
     * @memberof GameResultOutput
     */
    gamer: UserShort;
    /**
     * 
     * @type {number}
     * @memberof GameResultOutput
     */
    totalRounds: number;
}

/**
 * Check if a given object implements the GameResultOutput interface.
 */
export function instanceOfGameResultOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "income" in value;
    isInstance = isInstance && "gamer" in value;
    isInstance = isInstance && "totalRounds" in value;

    return isInstance;
}

export function GameResultOutputFromJSON(json: any): GameResultOutput {
    return GameResultOutputFromJSONTyped(json, false);
}

export function GameResultOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameResultOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rate': json['rate'],
        'income': json['income'],
        'gamer': UserShortFromJSON(json['gamer']),
        'totalRounds': json['total_rounds'],
    };
}

export function GameResultOutputToJSON(value?: GameResultOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rate': value.rate,
        'income': value.income,
        'gamer': UserShortToJSON(value.gamer),
        'total_rounds': value.totalRounds,
    };
}

