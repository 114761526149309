// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AffiseDataRequest,
  InternalReferralSystemSettings,
  LinkBindRequest,
  ReferralSystemAccount,
  ReferrerStatistics,
} from '../models/index';
import {
    AffiseDataRequestFromJSON,
    AffiseDataRequestToJSON,
    InternalReferralSystemSettingsFromJSON,
    InternalReferralSystemSettingsToJSON,
    LinkBindRequestFromJSON,
    LinkBindRequestToJSON,
    ReferralSystemAccountFromJSON,
    ReferralSystemAccountToJSON,
    ReferrerStatisticsFromJSON,
    ReferrerStatisticsToJSON,
} from '../models/index';

export interface ReferralApiAffiseBindRequest {
    affiseDataRequest: AffiseDataRequest;
}

export interface ReferralApiInternalAccountStatisticsRetrieveRequest {
    period: InternalAccountStatisticsRetrievePeriodEnum;
}

export interface ReferralApiInternalBindRequest {
    linkBindRequest: LinkBindRequest;
}

/**
 * 
 */
export class ReferralApi extends runtime.BaseAPI {

    /**
     */
    async affiseBindRaw(requestParameters: ReferralApiAffiseBindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['affiseDataRequest'] == null) {
            throw new runtime.RequiredError(
                'affiseDataRequest',
                'Required parameter "affiseDataRequest" was null or undefined when calling affiseBind().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral/affise/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AffiseDataRequestToJSON(requestParameters['affiseDataRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async affiseBind(requestParameters: ReferralApiAffiseBindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.affiseBindRaw(requestParameters, initOverrides);
    }

    /**
     */
    async internalAccountRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralSystemAccount>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral/internal/account/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralSystemAccountFromJSON(jsonValue));
    }

    /**
     */
    async internalAccountRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralSystemAccount> {
        const response = await this.internalAccountRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async internalAccountStatisticsRetrieveRaw(requestParameters: ReferralApiInternalAccountStatisticsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferrerStatistics>> {
        if (requestParameters['period'] == null) {
            throw new runtime.RequiredError(
                'period',
                'Required parameter "period" was null or undefined when calling internalAccountStatisticsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['period'] != null) {
            queryParameters['period'] = requestParameters['period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral/internal/account/statistics/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferrerStatisticsFromJSON(jsonValue));
    }

    /**
     */
    async internalAccountStatisticsRetrieve(requestParameters: ReferralApiInternalAccountStatisticsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferrerStatistics> {
        const response = await this.internalAccountStatisticsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async internalAccountTransferRevenueRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral/internal/account/transfer-revenue/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async internalAccountTransferRevenue(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.internalAccountTransferRevenueRaw(initOverrides);
    }

    /**
     */
    async internalBindRaw(requestParameters: ReferralApiInternalBindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['linkBindRequest'] == null) {
            throw new runtime.RequiredError(
                'linkBindRequest',
                'Required parameter "linkBindRequest" was null or undefined when calling internalBind().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral/internal/bind/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkBindRequestToJSON(requestParameters['linkBindRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async internalBind(requestParameters: ReferralApiInternalBindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.internalBindRaw(requestParameters, initOverrides);
    }

    /**
     */
    async internalRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InternalReferralSystemSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral/internal/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalReferralSystemSettingsFromJSON(jsonValue));
    }

    /**
     */
    async internalRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InternalReferralSystemSettings> {
        const response = await this.internalRetrieveRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const InternalAccountStatisticsRetrievePeriodEnum = {
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Total: 'TOTAL'
} as const;
export type InternalAccountStatisticsRetrievePeriodEnum = typeof InternalAccountStatisticsRetrievePeriodEnum[keyof typeof InternalAccountStatisticsRetrievePeriodEnum];
