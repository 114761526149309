// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TakeProfitResponse
 */
export interface TakeProfitResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof TakeProfitResponse
     */
    minesLocations: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TakeProfitResponse
     */
    openedLocations: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof TakeProfitResponse
     */
    rate: number;
}

/**
 * Check if a given object implements the TakeProfitResponse interface.
 */
export function instanceOfTakeProfitResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minesLocations" in value;
    isInstance = isInstance && "openedLocations" in value;
    isInstance = isInstance && "rate" in value;

    return isInstance;
}

export function TakeProfitResponseFromJSON(json: any): TakeProfitResponse {
    return TakeProfitResponseFromJSONTyped(json, false);
}

export function TakeProfitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TakeProfitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minesLocations': json['mines_locations'],
        'openedLocations': json['opened_locations'],
        'rate': json['rate'],
    };
}

export function TakeProfitResponseToJSON(value?: TakeProfitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mines_locations': value.minesLocations,
        'opened_locations': value.openedLocations,
        'rate': value.rate,
    };
}

