// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LadderGameStatusEnum } from './LadderGameStatusEnum';
import {
    LadderGameStatusEnumFromJSON,
    LadderGameStatusEnumFromJSONTyped,
    LadderGameStatusEnumToJSON,
} from './LadderGameStatusEnum';

/**
 * 
 * @export
 * @interface LadderGame
 */
export interface LadderGame {
    /**
     * 
     * @type {string}
     * @memberof LadderGame
     */
    id: string;
    /**
     * 
     * @type {LadderGameStatusEnum}
     * @memberof LadderGame
     */
    status: LadderGameStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof LadderGame
     */
    startRate: number;
    /**
     * 
     * @type {number}
     * @memberof LadderGame
     */
    numberBombs: number;
    /**
     * 
     * @type {Date}
     * @memberof LadderGame
     */
    started: Date;
    /**
     * 
     * @type {number}
     * @memberof LadderGame
     */
    currentFloor: number;
    /**
     * 
     * @type {string}
     * @memberof LadderGame
     */
    currentCoefficient: string;
    /**
     * 
     * @type {number}
     * @memberof LadderGame
     */
    currentRate: number;
    /**
     * 
     * @type {number}
     * @memberof LadderGame
     */
    currentPlatformNumber: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LadderGame
     */
    currentBombLocations: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof LadderGame
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof LadderGame
     */
    selectedNicknameDecoration: string;
}

/**
 * Check if a given object implements the LadderGame interface.
 */
export function instanceOfLadderGame(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "startRate" in value;
    isInstance = isInstance && "numberBombs" in value;
    isInstance = isInstance && "started" in value;
    isInstance = isInstance && "currentFloor" in value;
    isInstance = isInstance && "currentCoefficient" in value;
    isInstance = isInstance && "currentRate" in value;
    isInstance = isInstance && "currentPlatformNumber" in value;
    isInstance = isInstance && "currentBombLocations" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "selectedNicknameDecoration" in value;

    return isInstance;
}

export function LadderGameFromJSON(json: any): LadderGame {
    return LadderGameFromJSONTyped(json, false);
}

export function LadderGameFromJSONTyped(json: any, ignoreDiscriminator: boolean): LadderGame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': LadderGameStatusEnumFromJSON(json['status']),
        'startRate': json['start_rate'],
        'numberBombs': json['number_bombs'],
        'started': (new Date(json['started'])),
        'currentFloor': json['current_floor'],
        'currentCoefficient': json['current_coefficient'],
        'currentRate': json['current_rate'],
        'currentPlatformNumber': json['current_platform_number'],
        'currentBombLocations': json['current_bomb_locations'],
        'nickname': json['nickname'],
        'selectedNicknameDecoration': json['selected_nickname_decoration'],
    };
}

export function LadderGameToJSON(value?: LadderGame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': LadderGameStatusEnumToJSON(value.status),
        'start_rate': value.startRate,
        'number_bombs': value.numberBombs,
        'started': (value.started.toISOString()),
        'current_floor': value.currentFloor,
        'current_coefficient': value.currentCoefficient,
        'current_rate': value.currentRate,
        'current_platform_number': value.currentPlatformNumber,
        'current_bomb_locations': value.currentBombLocations,
        'nickname': value.nickname,
        'selected_nickname_decoration': value.selectedNicknameDecoration,
    };
}

