// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Subject } from './Subject';
import {
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';
import type { WithdrawalStatusEnum } from './WithdrawalStatusEnum';
import {
    WithdrawalStatusEnumFromJSON,
    WithdrawalStatusEnumFromJSONTyped,
    WithdrawalStatusEnumToJSON,
} from './WithdrawalStatusEnum';

/**
 * 
 * @export
 * @interface SkinsbackWithdrawalOrder
 */
export interface SkinsbackWithdrawalOrder {
    /**
     * 
     * @type {WithdrawalStatusEnum}
     * @memberof SkinsbackWithdrawalOrder
     */
    status: WithdrawalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SkinsbackWithdrawalOrder
     */
    error: string | null;
    /**
     * 
     * @type {number}
     * @memberof SkinsbackWithdrawalOrder
     */
    price: number;
    /**
     * 
     * @type {Subject}
     * @memberof SkinsbackWithdrawalOrder
     */
    subject: Subject;
    /**
     * 
     * @type {number}
     * @memberof SkinsbackWithdrawalOrder
     */
    readonly tradeOfferCreatedRemainingMinutes: number | null;
    /**
     * 
     * @type {number}
     * @memberof SkinsbackWithdrawalOrder
     */
    readonly tradeOfferAcceptedRemainingMinutes: number | null;
}

/**
 * Check if a given object implements the SkinsbackWithdrawalOrder interface.
 */
export function instanceOfSkinsbackWithdrawalOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "tradeOfferCreatedRemainingMinutes" in value;
    isInstance = isInstance && "tradeOfferAcceptedRemainingMinutes" in value;

    return isInstance;
}

export function SkinsbackWithdrawalOrderFromJSON(json: any): SkinsbackWithdrawalOrder {
    return SkinsbackWithdrawalOrderFromJSONTyped(json, false);
}

export function SkinsbackWithdrawalOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkinsbackWithdrawalOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': WithdrawalStatusEnumFromJSON(json['status']),
        'error': json['error'],
        'price': json['price'],
        'subject': SubjectFromJSON(json['subject']),
        'tradeOfferCreatedRemainingMinutes': json['trade_offer_created_remaining_minutes'],
        'tradeOfferAcceptedRemainingMinutes': json['trade_offer_accepted_remaining_minutes'],
    };
}

export function SkinsbackWithdrawalOrderToJSON(value?: SkinsbackWithdrawalOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': WithdrawalStatusEnumToJSON(value.status),
        'error': value.error,
        'price': value.price,
        'subject': SubjectToJSON(value.subject),
    };
}

