// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckEmail
 */
export interface CheckEmail {
    /**
     * 
     * @type {boolean}
     * @memberof CheckEmail
     */
    isEmailExists: boolean;
}

/**
 * Check if a given object implements the CheckEmail interface.
 */
export function instanceOfCheckEmail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEmailExists" in value;

    return isInstance;
}

export function CheckEmailFromJSON(json: any): CheckEmail {
    return CheckEmailFromJSONTyped(json, false);
}

export function CheckEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEmailExists': json['is_email_exists'],
    };
}

export function CheckEmailToJSON(value?: CheckEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_email_exists': value.isEmailExists,
    };
}

