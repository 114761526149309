// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `0` - 15 минут
 * * `1` - 1 час
 * * `2` - 1 день
 * * `3` - 1 неделя
 * * `4` - 1 месяц
 * * `5` - Навсегда
 * @export
 */
export const BanTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type BanTypeEnum = typeof BanTypeEnum[keyof typeof BanTypeEnum];


export function BanTypeEnumFromJSON(json: any): BanTypeEnum {
    return BanTypeEnumFromJSONTyped(json, false);
}

export function BanTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BanTypeEnum {
    return json as BanTypeEnum;
}

export function BanTypeEnumToJSON(value?: BanTypeEnum | null): any {
    return value as any;
}

