// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NextStepRequest
 */
export interface NextStepRequest {
    /**
     * 
     * @type {number}
     * @memberof NextStepRequest
     */
    cellNumber: number;
}

/**
 * Check if a given object implements the NextStepRequest interface.
 */
export function instanceOfNextStepRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cellNumber" in value;

    return isInstance;
}

export function NextStepRequestFromJSON(json: any): NextStepRequest {
    return NextStepRequestFromJSONTyped(json, false);
}

export function NextStepRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextStepRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cellNumber': json['cell_number'],
    };
}

export function NextStepRequestToJSON(value?: NextStepRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cell_number': value.cellNumber,
    };
}

