// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocialAuthResponse
 */
export interface SocialAuthResponse {
    /**
     * 
     * @type {string}
     * @memberof SocialAuthResponse
     */
    access: string;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthResponse
     */
    refresh: string;
    /**
     * 
     * @type {boolean}
     * @memberof SocialAuthResponse
     */
    isSignedUp: boolean;
}

/**
 * Check if a given object implements the SocialAuthResponse interface.
 */
export function instanceOfSocialAuthResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "access" in value;
    isInstance = isInstance && "refresh" in value;
    isInstance = isInstance && "isSignedUp" in value;

    return isInstance;
}

export function SocialAuthResponseFromJSON(json: any): SocialAuthResponse {
    return SocialAuthResponseFromJSONTyped(json, false);
}

export function SocialAuthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialAuthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'access': json['access'],
        'refresh': json['refresh'],
        'isSignedUp': json['is_signed_up'],
    };
}

export function SocialAuthResponseToJSON(value?: SocialAuthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access': value.access,
        'refresh': value.refresh,
        'is_signed_up': value.isSignedUp,
    };
}

