// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Loot } from './Loot';
import {
    LootFromJSON,
    LootFromJSONTyped,
    LootToJSON,
} from './Loot';

/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    img: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    description: string | null;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    readonly oldPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    discountPrice: number | null;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    readonly collectionId: number | null;
    /**
     * 
     * @type {Array<Loot>}
     * @memberof Case
     */
    readonly subjects: Array<Loot>;
}

/**
 * Check if a given object implements the Case interface.
 */
export function instanceOfCase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "oldPrice" in value;
    isInstance = isInstance && "discountPrice" in value;
    isInstance = isInstance && "collectionId" in value;
    isInstance = isInstance && "subjects" in value;

    return isInstance;
}

export function CaseFromJSON(json: any): Case {
    return CaseFromJSONTyped(json, false);
}

export function CaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Case {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'img': json['img'],
        'name': json['name'],
        'description': json['description'],
        'price': json['price'],
        'oldPrice': json['old_price'],
        'discountPrice': json['discount_price'],
        'collectionId': json['collection_id'],
        'subjects': ((json['subjects'] as Array<any>).map(LootFromJSON)),
    };
}

export function CaseToJSON(value?: Case | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'img': value.img,
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'discount_price': value.discountPrice,
    };
}

