// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ru` - Russian
 * * `en` - English
 * @export
 */
export const LanguageEnum = {
    Ru: 'ru',
    En: 'en'
} as const;
export type LanguageEnum = typeof LanguageEnum[keyof typeof LanguageEnum];


export function LanguageEnumFromJSON(json: any): LanguageEnum {
    return LanguageEnumFromJSONTyped(json, false);
}

export function LanguageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageEnum {
    return json as LanguageEnum;
}

export function LanguageEnumToJSON(value?: LanguageEnum | null): any {
    return value as any;
}

