// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BankComplianceCheck,
  ChangePasswordRequest,
  CheckEmail,
  CheckEmailInRequest,
  ConfirmCodeRequest,
  EmailChangeConfirmRequest,
  EmailChangeResponse,
  EmailConfirmRequest,
  HasPassword,
  LanguageRequest,
  LoginLogRequest,
  PatchedUserRequest,
  PhoneRequest,
  ResetPasswordRequest,
  Socials,
  TokenObtainRequest,
  TokenPair,
  TokenRefreshRequest,
  UpdateAvatar,
  User,
  UserRequest,
  VkUnbindTime,
} from '../models/index';
import {
    BankComplianceCheckFromJSON,
    BankComplianceCheckToJSON,
    ChangePasswordRequestFromJSON,
    ChangePasswordRequestToJSON,
    CheckEmailFromJSON,
    CheckEmailToJSON,
    CheckEmailInRequestFromJSON,
    CheckEmailInRequestToJSON,
    ConfirmCodeRequestFromJSON,
    ConfirmCodeRequestToJSON,
    EmailChangeConfirmRequestFromJSON,
    EmailChangeConfirmRequestToJSON,
    EmailChangeResponseFromJSON,
    EmailChangeResponseToJSON,
    EmailConfirmRequestFromJSON,
    EmailConfirmRequestToJSON,
    HasPasswordFromJSON,
    HasPasswordToJSON,
    LanguageRequestFromJSON,
    LanguageRequestToJSON,
    LoginLogRequestFromJSON,
    LoginLogRequestToJSON,
    PatchedUserRequestFromJSON,
    PatchedUserRequestToJSON,
    PhoneRequestFromJSON,
    PhoneRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    SocialsFromJSON,
    SocialsToJSON,
    TokenObtainRequestFromJSON,
    TokenObtainRequestToJSON,
    TokenPairFromJSON,
    TokenPairToJSON,
    TokenRefreshRequestFromJSON,
    TokenRefreshRequestToJSON,
    UpdateAvatarFromJSON,
    UpdateAvatarToJSON,
    UserFromJSON,
    UserToJSON,
    UserRequestFromJSON,
    UserRequestToJSON,
    VkUnbindTimeFromJSON,
    VkUnbindTimeToJSON,
} from '../models/index';

export interface UserApiAvatarPartialUpdateRequest {
    avatarImg?: Blob | null;
    defaultAvatarImg?: number;
    avatarBorder?: number | null;
}

export interface UserApiAvatarUpdateRequest {
    avatarImg?: Blob | null;
    defaultAvatarImg?: number;
    avatarBorder?: number | null;
}

export interface UserApiCallRequest {
    phoneRequest: PhoneRequest;
}

export interface UserApiChangeEmailRequest {
    emailChangeConfirmRequest: EmailChangeConfirmRequest;
}

export interface UserApiChangePasswordOperationRequest {
    changePasswordRequest: ChangePasswordRequest;
}

export interface UserApiCheckEmailRequest {
    checkEmailInRequest: CheckEmailInRequest;
}

export interface UserApiConfirmRequest {
    emailConfirmRequest: EmailConfirmRequest;
}

export interface UserApiConfirmPhoneRequest {
    confirmCodeRequest: ConfirmCodeRequest;
}

export interface UserApiCreateCreateRequest {
    userRequest: UserRequest;
}

export interface UserApiLoginLogCreateRequest {
    loginLogRequest: LoginLogRequest;
}

export interface UserApiProfilePartialUpdateRequest {
    patchedUserRequest?: PatchedUserRequest;
}

export interface UserApiProfileUpdateRequest {
    userRequest: UserRequest;
}

export interface UserApiResetPasswordOperationRequest {
    resetPasswordRequest: ResetPasswordRequest;
}

export interface UserApiSetLanguageRequest {
    languageRequest: LanguageRequest;
}

export interface UserApiTokenRequest {
    tokenObtainRequest: TokenObtainRequest;
}

export interface UserApiTokenRefreshOperationRequest {
    tokenRefreshRequest: TokenRefreshRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async avatarPartialUpdateRaw(requestParameters: UserApiAvatarPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateAvatar>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['avatarImg'] != null) {
            formParams.append('avatar_img', requestParameters['avatarImg'] as any);
        }

        if (requestParameters['defaultAvatarImg'] != null) {
            formParams.append('default_avatar_img', requestParameters['defaultAvatarImg'] as any);
        }

        if (requestParameters['avatarBorder'] != null) {
            formParams.append('avatar_border', requestParameters['avatarBorder'] as any);
        }

        const response = await this.request({
            path: `/api/user/avatar/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateAvatarFromJSON(jsonValue));
    }

    /**
     */
    async avatarPartialUpdate(requestParameters: UserApiAvatarPartialUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateAvatar> {
        const response = await this.avatarPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async avatarUpdateRaw(requestParameters: UserApiAvatarUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateAvatar>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['avatarImg'] != null) {
            formParams.append('avatar_img', requestParameters['avatarImg'] as any);
        }

        if (requestParameters['defaultAvatarImg'] != null) {
            formParams.append('default_avatar_img', requestParameters['defaultAvatarImg'] as any);
        }

        if (requestParameters['avatarBorder'] != null) {
            formParams.append('avatar_border', requestParameters['avatarBorder'] as any);
        }

        const response = await this.request({
            path: `/api/user/avatar/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateAvatarFromJSON(jsonValue));
    }

    /**
     */
    async avatarUpdate(requestParameters: UserApiAvatarUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateAvatar> {
        const response = await this.avatarUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bankComplianceRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankComplianceCheck>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/bank-compliance/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankComplianceCheckFromJSON(jsonValue));
    }

    /**
     */
    async bankComplianceRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankComplianceCheck> {
        const response = await this.bankComplianceRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async callRaw(requestParameters: UserApiCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['phoneRequest'] == null) {
            throw new runtime.RequiredError(
                'phoneRequest',
                'Required parameter "phoneRequest" was null or undefined when calling call().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/call/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneRequestToJSON(requestParameters['phoneRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async call(requestParameters: UserApiCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.callRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeEmailRaw(requestParameters: UserApiChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailChangeResponse>> {
        if (requestParameters['emailChangeConfirmRequest'] == null) {
            throw new runtime.RequiredError(
                'emailChangeConfirmRequest',
                'Required parameter "emailChangeConfirmRequest" was null or undefined when calling changeEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/change-email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailChangeConfirmRequestToJSON(requestParameters['emailChangeConfirmRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailChangeResponseFromJSON(jsonValue));
    }

    /**
     */
    async changeEmail(requestParameters: UserApiChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailChangeResponse> {
        const response = await this.changeEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changePasswordRaw(requestParameters: UserApiChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenPair>> {
        if (requestParameters['changePasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'changePasswordRequest',
                'Required parameter "changePasswordRequest" was null or undefined when calling changePassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/change-password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters['changePasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenPairFromJSON(jsonValue));
    }

    /**
     */
    async changePassword(requestParameters: UserApiChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenPair> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async checkEmailRaw(requestParameters: UserApiCheckEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckEmail>> {
        if (requestParameters['checkEmailInRequest'] == null) {
            throw new runtime.RequiredError(
                'checkEmailInRequest',
                'Required parameter "checkEmailInRequest" was null or undefined when calling checkEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/check-email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckEmailInRequestToJSON(requestParameters['checkEmailInRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckEmailFromJSON(jsonValue));
    }

    /**
     */
    async checkEmail(requestParameters: UserApiCheckEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckEmail> {
        const response = await this.checkEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async confirmRaw(requestParameters: UserApiConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenPair>> {
        if (requestParameters['emailConfirmRequest'] == null) {
            throw new runtime.RequiredError(
                'emailConfirmRequest',
                'Required parameter "emailConfirmRequest" was null or undefined when calling confirm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailConfirmRequestToJSON(requestParameters['emailConfirmRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenPairFromJSON(jsonValue));
    }

    /**
     */
    async confirm(requestParameters: UserApiConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenPair> {
        const response = await this.confirmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async confirmPhoneRaw(requestParameters: UserApiConfirmPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['confirmCodeRequest'] == null) {
            throw new runtime.RequiredError(
                'confirmCodeRequest',
                'Required parameter "confirmCodeRequest" was null or undefined when calling confirmPhone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/confirm-phone/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmCodeRequestToJSON(requestParameters['confirmCodeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmPhone(requestParameters: UserApiConfirmPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmPhoneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createCreateRaw(requestParameters: UserApiCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['userRequest'] == null) {
            throw new runtime.RequiredError(
                'userRequest',
                'Required parameter "userRequest" was null or undefined when calling createCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRequestToJSON(requestParameters['userRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async createCreate(requestParameters: UserApiCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async hasPasswordRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HasPassword>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/has-password/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HasPasswordFromJSON(jsonValue));
    }

    /**
     */
    async hasPasswordRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HasPassword> {
        const response = await this.hasPasswordRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async loginLogCreateRaw(requestParameters: UserApiLoginLogCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['loginLogRequest'] == null) {
            throw new runtime.RequiredError(
                'loginLogRequest',
                'Required parameter "loginLogRequest" was null or undefined when calling loginLogCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/login-log/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginLogRequestToJSON(requestParameters['loginLogRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async loginLogCreate(requestParameters: UserApiLoginLogCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.loginLogCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async profilePartialUpdateRaw(requestParameters: UserApiProfilePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/profile/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedUserRequestToJSON(requestParameters['patchedUserRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async profilePartialUpdate(requestParameters: UserApiProfilePartialUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.profilePartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/profile/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async profileRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.profileRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profileSocialsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Socials>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/profile/socials/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialsFromJSON(jsonValue));
    }

    /**
     */
    async profileSocialsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Socials> {
        const response = await this.profileSocialsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profileUpdateRaw(requestParameters: UserApiProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['userRequest'] == null) {
            throw new runtime.RequiredError(
                'userRequest',
                'Required parameter "userRequest" was null or undefined when calling profileUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/profile/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserRequestToJSON(requestParameters['userRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async profileUpdate(requestParameters: UserApiProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.profileUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resetPasswordRaw(requestParameters: UserApiResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['resetPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'resetPasswordRequest',
                'Required parameter "resetPasswordRequest" was null or undefined when calling resetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/reset-password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters['resetPasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPassword(requestParameters: UserApiResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setLanguageRaw(requestParameters: UserApiSetLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['languageRequest'] == null) {
            throw new runtime.RequiredError(
                'languageRequest',
                'Required parameter "languageRequest" was null or undefined when calling setLanguage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/set-language/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LanguageRequestToJSON(requestParameters['languageRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLanguage(requestParameters: UserApiSetLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setLanguageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tokenRaw(requestParameters: UserApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenPair>> {
        if (requestParameters['tokenObtainRequest'] == null) {
            throw new runtime.RequiredError(
                'tokenObtainRequest',
                'Required parameter "tokenObtainRequest" was null or undefined when calling token().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenObtainRequestToJSON(requestParameters['tokenObtainRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenPairFromJSON(jsonValue));
    }

    /**
     */
    async token(requestParameters: UserApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenPair> {
        const response = await this.tokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tokenRefreshRaw(requestParameters: UserApiTokenRefreshOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenPair>> {
        if (requestParameters['tokenRefreshRequest'] == null) {
            throw new runtime.RequiredError(
                'tokenRefreshRequest',
                'Required parameter "tokenRefreshRequest" was null or undefined when calling tokenRefresh().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/token/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshRequestToJSON(requestParameters['tokenRefreshRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenPairFromJSON(jsonValue));
    }

    /**
     */
    async tokenRefresh(requestParameters: UserApiTokenRefreshOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenPair> {
        const response = await this.tokenRefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async vkUnbindTimeRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VkUnbindTime>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/vk-unbind-time/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VkUnbindTimeFromJSON(jsonValue));
    }

    /**
     */
    async vkUnbindTimeRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VkUnbindTime> {
        const response = await this.vkUnbindTimeRetrieveRaw(initOverrides);
        return await response.value();
    }

}
