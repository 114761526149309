// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankComplianceCheck
 */
export interface BankComplianceCheck {
    /**
     * 
     * @type {boolean}
     * @memberof BankComplianceCheck
     */
    canViewGames: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BankComplianceCheck
     */
    canViewBullpass: boolean;
}

/**
 * Check if a given object implements the BankComplianceCheck interface.
 */
export function instanceOfBankComplianceCheck(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "canViewGames" in value;
    isInstance = isInstance && "canViewBullpass" in value;

    return isInstance;
}

export function BankComplianceCheckFromJSON(json: any): BankComplianceCheck {
    return BankComplianceCheckFromJSONTyped(json, false);
}

export function BankComplianceCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankComplianceCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canViewGames': json['can_view_games'],
        'canViewBullpass': json['can_view_bullpass'],
    };
}

export function BankComplianceCheckToJSON(value?: BankComplianceCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_view_games': value.canViewGames,
        'can_view_bullpass': value.canViewBullpass,
    };
}

