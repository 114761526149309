// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BattlePassLevel } from './BattlePassLevel';
import {
    BattlePassLevelFromJSON,
    BattlePassLevelFromJSONTyped,
    BattlePassLevelToJSON,
} from './BattlePassLevel';
import type { CurrentLevel } from './CurrentLevel';
import {
    CurrentLevelFromJSON,
    CurrentLevelFromJSONTyped,
    CurrentLevelToJSON,
} from './CurrentLevel';

/**
 * 
 * @export
 * @interface BattlePassInfo
 */
export interface BattlePassInfo {
    /**
     * 
     * @type {Date}
     * @memberof BattlePassInfo
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BattlePassInfo
     */
    endDate: Date;
    /**
     * 
     * @type {Array<BattlePassLevel>}
     * @memberof BattlePassInfo
     */
    levels: Array<BattlePassLevel>;
    /**
     * 
     * @type {CurrentLevel}
     * @memberof BattlePassInfo
     */
    currentLevel: CurrentLevel | null;
}

/**
 * Check if a given object implements the BattlePassInfo interface.
 */
export function instanceOfBattlePassInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "levels" in value;
    isInstance = isInstance && "currentLevel" in value;

    return isInstance;
}

export function BattlePassInfoFromJSON(json: any): BattlePassInfo {
    return BattlePassInfoFromJSONTyped(json, false);
}

export function BattlePassInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BattlePassInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'levels': ((json['levels'] as Array<any>).map(BattlePassLevelFromJSON)),
        'currentLevel': CurrentLevelFromJSON(json['current_level']),
    };
}

export function BattlePassInfoToJSON(value?: BattlePassInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': (value.startDate.toISOString()),
        'end_date': (value.endDate.toISOString()),
        'levels': ((value.levels as Array<any>).map(BattlePassLevelToJSON)),
        'current_level': CurrentLevelToJSON(value.currentLevel),
    };
}

