// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `easy` - Easy
 * * `hard` - Hard
 * @export
 */
export const ModeEnum = {
    Easy: 'easy',
    Hard: 'hard'
} as const;
export type ModeEnum = typeof ModeEnum[keyof typeof ModeEnum];


export function ModeEnumFromJSON(json: any): ModeEnum {
    return ModeEnumFromJSONTyped(json, false);
}

export function ModeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModeEnum {
    return json as ModeEnum;
}

export function ModeEnumToJSON(value?: ModeEnum | null): any {
    return value as any;
}

