// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShortUserWithoutChatBan } from './ShortUserWithoutChatBan';
import {
    ShortUserWithoutChatBanFromJSON,
    ShortUserWithoutChatBanFromJSONTyped,
    ShortUserWithoutChatBanToJSON,
} from './ShortUserWithoutChatBan';
import type { StandoffSubject } from './StandoffSubject';
import {
    StandoffSubjectFromJSON,
    StandoffSubjectFromJSONTyped,
    StandoffSubjectToJSON,
} from './StandoffSubject';

/**
 * 
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
    /**
     * 
     * @type {ShortUserWithoutChatBan}
     * @memberof CurrentUser
     */
    user: ShortUserWithoutChatBan;
    /**
     * 
     * @type {number}
     * @memberof CurrentUser
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentUser
     */
    count: number;
    /**
     * 
     * @type {StandoffSubject}
     * @memberof CurrentUser
     */
    reward: StandoffSubject;
}

/**
 * Check if a given object implements the CurrentUser interface.
 */
export function instanceOfCurrentUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "reward" in value;

    return isInstance;
}

export function CurrentUserFromJSON(json: any): CurrentUser {
    return CurrentUserFromJSONTyped(json, false);
}

export function CurrentUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': ShortUserWithoutChatBanFromJSON(json['user']),
        'number': json['number'],
        'count': json['count'],
        'reward': StandoffSubjectFromJSON(json['reward']),
    };
}

export function CurrentUserToJSON(value?: CurrentUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': ShortUserWithoutChatBanToJSON(value.user),
        'number': value.number,
        'count': value.count,
        'reward': StandoffSubjectToJSON(value.reward),
    };
}

