// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DefaultAvatar
 */
export interface DefaultAvatar {
    /**
     * 
     * @type {number}
     * @memberof DefaultAvatar
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultAvatar
     */
    image: string | null;
}

/**
 * Check if a given object implements the DefaultAvatar interface.
 */
export function instanceOfDefaultAvatar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "image" in value;

    return isInstance;
}

export function DefaultAvatarFromJSON(json: any): DefaultAvatar {
    return DefaultAvatarFromJSONTyped(json, false);
}

export function DefaultAvatarFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultAvatar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'image': json['image'],
    };
}

export function DefaultAvatarToJSON(value?: DefaultAvatar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': value.image,
    };
}

