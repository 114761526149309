// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Subject } from './Subject';
import {
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface SockWithSubjectReward
 */
export interface SockWithSubjectReward {
    /**
     * 
     * @type {Subject}
     * @memberof SockWithSubjectReward
     */
    subject: Subject;
}

/**
 * Check if a given object implements the SockWithSubjectReward interface.
 */
export function instanceOfSockWithSubjectReward(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subject" in value;

    return isInstance;
}

export function SockWithSubjectRewardFromJSON(json: any): SockWithSubjectReward {
    return SockWithSubjectRewardFromJSONTyped(json, false);
}

export function SockWithSubjectRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): SockWithSubjectReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': SubjectFromJSON(json['subject']),
    };
}

export function SockWithSubjectRewardToJSON(value?: SockWithSubjectReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': SubjectToJSON(value.subject),
    };
}

