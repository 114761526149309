// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MakeWithdrawalPlatformEnum } from './MakeWithdrawalPlatformEnum';
import {
    MakeWithdrawalPlatformEnumFromJSON,
    MakeWithdrawalPlatformEnumFromJSONTyped,
    MakeWithdrawalPlatformEnumToJSON,
} from './MakeWithdrawalPlatformEnum';

/**
 * 
 * @export
 * @interface MakeWithdrawalRequest
 */
export interface MakeWithdrawalRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof MakeWithdrawalRequest
     */
    marketSubjects?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MakeWithdrawalRequest
     */
    inventoryItems?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof MakeWithdrawalRequest
     */
    tradeUrl: string;
    /**
     * 
     * @type {MakeWithdrawalPlatformEnum}
     * @memberof MakeWithdrawalRequest
     */
    platform?: MakeWithdrawalPlatformEnum;
}

/**
 * Check if a given object implements the MakeWithdrawalRequest interface.
 */
export function instanceOfMakeWithdrawalRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tradeUrl" in value;

    return isInstance;
}

export function MakeWithdrawalRequestFromJSON(json: any): MakeWithdrawalRequest {
    return MakeWithdrawalRequestFromJSONTyped(json, false);
}

export function MakeWithdrawalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MakeWithdrawalRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketSubjects': !exists(json, 'market_subjects') ? undefined : json['market_subjects'],
        'inventoryItems': !exists(json, 'inventory_items') ? undefined : json['inventory_items'],
        'tradeUrl': json['trade_url'],
        'platform': !exists(json, 'platform') ? undefined : MakeWithdrawalPlatformEnumFromJSON(json['platform']),
    };
}

export function MakeWithdrawalRequestToJSON(value?: MakeWithdrawalRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'market_subjects': value.marketSubjects,
        'inventory_items': value.inventoryItems,
        'trade_url': value.tradeUrl,
        'platform': MakeWithdrawalPlatformEnumToJSON(value.platform),
    };
}

