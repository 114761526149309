// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VkUnbindTime
 */
export interface VkUnbindTime {
    /**
     * 
     * @type {number}
     * @memberof VkUnbindTime
     */
    readonly vkUnbindTime: number;
}

/**
 * Check if a given object implements the VkUnbindTime interface.
 */
export function instanceOfVkUnbindTime(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vkUnbindTime" in value;

    return isInstance;
}

export function VkUnbindTimeFromJSON(json: any): VkUnbindTime {
    return VkUnbindTimeFromJSONTyped(json, false);
}

export function VkUnbindTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): VkUnbindTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vkUnbindTime': json['vk_unbind_time'],
    };
}

export function VkUnbindTimeToJSON(value?: VkUnbindTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

