// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferrerAccount
 */
export interface ReferrerAccount {
    /**
     * 
     * @type {number}
     * @memberof ReferrerAccount
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ReferrerAccount
     */
    readonly balance: string;
    /**
     * 
     * @type {Date}
     * @memberof ReferrerAccount
     */
    readonly created: Date;
    /**
     * 
     * @type {string}
     * @memberof ReferrerAccount
     */
    readonly referralLink: string;
}

/**
 * Check if a given object implements the ReferrerAccount interface.
 */
export function instanceOfReferrerAccount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "referralLink" in value;

    return isInstance;
}

export function ReferrerAccountFromJSON(json: any): ReferrerAccount {
    return ReferrerAccountFromJSONTyped(json, false);
}

export function ReferrerAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferrerAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'balance': json['balance'],
        'created': (new Date(json['created'])),
        'referralLink': json['referral_link'],
    };
}

export function ReferrerAccountToJSON(value?: ReferrerAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

