// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AvatarBorder } from './AvatarBorder';
import {
    AvatarBorderFromJSON,
    AvatarBorderFromJSONTyped,
    AvatarBorderToJSON,
} from './AvatarBorder';
import type { NicknameDecorationsEnum } from './NicknameDecorationsEnum';
import {
    NicknameDecorationsEnumFromJSON,
    NicknameDecorationsEnumFromJSONTyped,
    NicknameDecorationsEnumToJSON,
} from './NicknameDecorationsEnum';

/**
 * 
 * @export
 * @interface UserShort
 */
export interface UserShort {
    /**
     * 
     * @type {number}
     * @memberof UserShort
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    readonly nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    readonly avatar: string;
    /**
     * 
     * @type {AvatarBorder}
     * @memberof UserShort
     */
    readonly avatarBorder: AvatarBorder;
    /**
     * 
     * @type {boolean}
     * @memberof UserShort
     */
    readonly banInChat: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserShort
     */
    readonly isStaff: boolean;
    /**
     * 
     * @type {NicknameDecorationsEnum}
     * @memberof UserShort
     */
    readonly selectedNicknameDecoration: NicknameDecorationsEnum;
}

/**
 * Check if a given object implements the UserShort interface.
 */
export function instanceOfUserShort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "avatar" in value;
    isInstance = isInstance && "avatarBorder" in value;
    isInstance = isInstance && "banInChat" in value;
    isInstance = isInstance && "isStaff" in value;
    isInstance = isInstance && "selectedNicknameDecoration" in value;

    return isInstance;
}

export function UserShortFromJSON(json: any): UserShort {
    return UserShortFromJSONTyped(json, false);
}

export function UserShortFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserShort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nickname': json['nickname'],
        'avatar': json['avatar'],
        'avatarBorder': AvatarBorderFromJSON(json['avatar_border']),
        'banInChat': json['ban_in_chat'],
        'isStaff': json['is_staff'],
        'selectedNicknameDecoration': NicknameDecorationsEnumFromJSON(json['selected_nickname_decoration']),
    };
}

export function UserShortToJSON(value?: UserShort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

