// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WithdrawalStatusEnum } from './WithdrawalStatusEnum';
import {
    WithdrawalStatusEnumFromJSON,
    WithdrawalStatusEnumFromJSONTyped,
    WithdrawalStatusEnumToJSON,
} from './WithdrawalStatusEnum';

/**
 * 
 * @export
 * @interface StandoffWithdrawalTyped
 */
export interface StandoffWithdrawalTyped {
    /**
     * 
     * @type {number}
     * @memberof StandoffWithdrawalTyped
     */
    amountGold: number;
    /**
     * 
     * @type {number}
     * @memberof StandoffWithdrawalTyped
     */
    amountBullcoins: number;
    /**
     * 
     * @type {number}
     * @memberof StandoffWithdrawalTyped
     */
    surplus: number;
    /**
     * 
     * @type {string}
     * @memberof StandoffWithdrawalTyped
     */
    gameAccountId: string | null;
    /**
     * 
     * @type {WithdrawalStatusEnum}
     * @memberof StandoffWithdrawalTyped
     */
    status: WithdrawalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StandoffWithdrawalTyped
     */
    gameAvatar: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StandoffWithdrawalTyped
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof StandoffWithdrawalTyped
     */
    cancelComment: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandoffWithdrawalTyped
     */
    skinPicture: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffWithdrawalTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the StandoffWithdrawalTyped interface.
 */
export function instanceOfStandoffWithdrawalTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amountGold" in value;
    isInstance = isInstance && "amountBullcoins" in value;
    isInstance = isInstance && "surplus" in value;
    isInstance = isInstance && "gameAccountId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "gameAvatar" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "cancelComment" in value;
    isInstance = isInstance && "skinPicture" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function StandoffWithdrawalTypedFromJSON(json: any): StandoffWithdrawalTyped {
    return StandoffWithdrawalTypedFromJSONTyped(json, false);
}

export function StandoffWithdrawalTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandoffWithdrawalTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountGold': json['amount_gold'],
        'amountBullcoins': json['amount_bullcoins'],
        'surplus': json['surplus'],
        'gameAccountId': json['game_account_id'],
        'status': WithdrawalStatusEnumFromJSON(json['status']),
        'gameAvatar': json['game_avatar'],
        'created': (new Date(json['created'])),
        'cancelComment': json['cancel_comment'],
        'skinPicture': json['skin_picture'],
        'resourcetype': json['resourcetype'],
    };
}

export function StandoffWithdrawalTypedToJSON(value?: StandoffWithdrawalTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_gold': value.amountGold,
        'amount_bullcoins': value.amountBullcoins,
        'surplus': value.surplus,
        'game_account_id': value.gameAccountId,
        'status': WithdrawalStatusEnumToJSON(value.status),
        'game_avatar': value.gameAvatar,
        'created': (value.created.toISOString()),
        'cancel_comment': value.cancelComment,
        'skin_picture': value.skinPicture,
        'resourcetype': value.resourcetype,
    };
}

