// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Chat,
  ChatBanRequest,
  ChatCooldown,
  ChatMessage,
  ChatMessageRequest,
  ChatOnline,
  ChatSettingsUpdate,
  ChatSettingsUpdateRequest,
  ChatUnbanRequest,
  PaginatedChatMessageFullList,
  PatchedChatSettingsUpdateRequest,
  PinMessageRequest,
  Reactions,
  ReportRequest,
  RequestChangeNicknameRequest,
  SetCooldownRequest,
  SetRemoveReactionRequest,
  UserInfo,
} from '../models/index';
import {
    ChatFromJSON,
    ChatToJSON,
    ChatBanRequestFromJSON,
    ChatBanRequestToJSON,
    ChatCooldownFromJSON,
    ChatCooldownToJSON,
    ChatMessageFromJSON,
    ChatMessageToJSON,
    ChatMessageRequestFromJSON,
    ChatMessageRequestToJSON,
    ChatOnlineFromJSON,
    ChatOnlineToJSON,
    ChatSettingsUpdateFromJSON,
    ChatSettingsUpdateToJSON,
    ChatSettingsUpdateRequestFromJSON,
    ChatSettingsUpdateRequestToJSON,
    ChatUnbanRequestFromJSON,
    ChatUnbanRequestToJSON,
    PaginatedChatMessageFullListFromJSON,
    PaginatedChatMessageFullListToJSON,
    PatchedChatSettingsUpdateRequestFromJSON,
    PatchedChatSettingsUpdateRequestToJSON,
    PinMessageRequestFromJSON,
    PinMessageRequestToJSON,
    ReactionsFromJSON,
    ReactionsToJSON,
    ReportRequestFromJSON,
    ReportRequestToJSON,
    RequestChangeNicknameRequestFromJSON,
    RequestChangeNicknameRequestToJSON,
    SetCooldownRequestFromJSON,
    SetCooldownRequestToJSON,
    SetRemoveReactionRequestFromJSON,
    SetRemoveReactionRequestToJSON,
    UserInfoFromJSON,
    UserInfoToJSON,
} from '../models/index';

export interface ChatApiBanRequest {
    chatBanRequest: ChatBanRequest;
}

export interface ChatApiChatMessagesListRequest {
    room: string;
    cursor?: string;
}

export interface ChatApiChatMessagesRoomListRequest {
    room: string;
    roomId: number;
    cursor?: string;
}

export interface ChatApiCooldownSetRequest {
    setCooldownRequest: SetCooldownRequest;
}

export interface ChatApiMessageDeleteDestroyRequest {
    messageId: number;
}

export interface ChatApiMessageSendCreateRequest {
    chatMessageRequest: ChatMessageRequest;
}

export interface ChatApiMessagesRemoveReactionRequest {
    id: number;
    setRemoveReactionRequest: SetRemoveReactionRequest;
}

export interface ChatApiMessagesSetReactionRequest {
    id: number;
    setRemoveReactionRequest: SetRemoveReactionRequest;
}

export interface ChatApiPinMessageOperationRequest {
    pinMessageRequest: PinMessageRequest;
}

export interface ChatApiReportMessageRequest {
    reportRequest: ReportRequest;
}

export interface ChatApiRequestChangeNicknameOperationRequest {
    requestChangeNicknameRequest: RequestChangeNicknameRequest;
}

export interface ChatApiUnbanRequest {
    chatUnbanRequest: ChatUnbanRequest;
}

export interface ChatApiUpdatePartialUpdateRequest {
    patchedChatSettingsUpdateRequest?: PatchedChatSettingsUpdateRequest;
}

export interface ChatApiUpdateUpdateRequest {
    chatSettingsUpdateRequest: ChatSettingsUpdateRequest;
}

export interface ChatApiUserRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class ChatApi extends runtime.BaseAPI {

    /**
     */
    async banRaw(requestParameters: ChatApiBanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['chatBanRequest'] == null) {
            throw new runtime.RequiredError(
                'chatBanRequest',
                'Required parameter "chatBanRequest" was null or undefined when calling ban().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/ban/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatBanRequestToJSON(requestParameters['chatBanRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ban(requestParameters: ChatApiBanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.banRaw(requestParameters, initOverrides);
    }

    /**
     */
    async chatMessagesListRaw(requestParameters: ChatApiChatMessagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedChatMessageFullList>> {
        if (requestParameters['room'] == null) {
            throw new runtime.RequiredError(
                'room',
                'Required parameter "room" was null or undefined when calling chatMessagesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/messages/{room}/`.replace(`{${"room"}}`, encodeURIComponent(String(requestParameters['room']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedChatMessageFullListFromJSON(jsonValue));
    }

    /**
     */
    async chatMessagesList(requestParameters: ChatApiChatMessagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedChatMessageFullList> {
        const response = await this.chatMessagesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chatMessagesRoomListRaw(requestParameters: ChatApiChatMessagesRoomListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedChatMessageFullList>> {
        if (requestParameters['room'] == null) {
            throw new runtime.RequiredError(
                'room',
                'Required parameter "room" was null or undefined when calling chatMessagesRoomList().'
            );
        }

        if (requestParameters['roomId'] == null) {
            throw new runtime.RequiredError(
                'roomId',
                'Required parameter "roomId" was null or undefined when calling chatMessagesRoomList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/messages/{room}/{room_id}/`.replace(`{${"room"}}`, encodeURIComponent(String(requestParameters['room']))).replace(`{${"room_id"}}`, encodeURIComponent(String(requestParameters['roomId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedChatMessageFullListFromJSON(jsonValue));
    }

    /**
     */
    async chatMessagesRoomList(requestParameters: ChatApiChatMessagesRoomListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedChatMessageFullList> {
        const response = await this.chatMessagesRoomListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cooldownRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatCooldown>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/cooldown/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatCooldownFromJSON(jsonValue));
    }

    /**
     */
    async cooldownRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatCooldown> {
        const response = await this.cooldownRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async cooldownSetRaw(requestParameters: ChatApiCooldownSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['setCooldownRequest'] == null) {
            throw new runtime.RequiredError(
                'setCooldownRequest',
                'Required parameter "setCooldownRequest" was null or undefined when calling cooldownSet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/cooldown/set/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetCooldownRequestToJSON(requestParameters['setCooldownRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cooldownSet(requestParameters: ChatApiCooldownSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cooldownSetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getOnlineRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatOnline>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/get_online/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatOnlineFromJSON(jsonValue));
    }

    /**
     */
    async getOnlineRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatOnline> {
        const response = await this.getOnlineRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async messageDeleteDestroyRaw(requestParameters: ChatApiMessageDeleteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['messageId'] == null) {
            throw new runtime.RequiredError(
                'messageId',
                'Required parameter "messageId" was null or undefined when calling messageDeleteDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/message/{message_id}/delete/`.replace(`{${"message_id"}}`, encodeURIComponent(String(requestParameters['messageId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async messageDeleteDestroy(requestParameters: ChatApiMessageDeleteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.messageDeleteDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async messageSendCreateRaw(requestParameters: ChatApiMessageSendCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatMessage>> {
        if (requestParameters['chatMessageRequest'] == null) {
            throw new runtime.RequiredError(
                'chatMessageRequest',
                'Required parameter "chatMessageRequest" was null or undefined when calling messageSendCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/message/send/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageRequestToJSON(requestParameters['chatMessageRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageFromJSON(jsonValue));
    }

    /**
     */
    async messageSendCreate(requestParameters: ChatApiMessageSendCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatMessage> {
        const response = await this.messageSendCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async messagesRemoveReactionRaw(requestParameters: ChatApiMessagesRemoveReactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling messagesRemoveReaction().'
            );
        }

        if (requestParameters['setRemoveReactionRequest'] == null) {
            throw new runtime.RequiredError(
                'setRemoveReactionRequest',
                'Required parameter "setRemoveReactionRequest" was null or undefined when calling messagesRemoveReaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/messages/{id}/remove-reaction/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetRemoveReactionRequestToJSON(requestParameters['setRemoveReactionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async messagesRemoveReaction(requestParameters: ChatApiMessagesRemoveReactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.messagesRemoveReactionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async messagesSetReactionRaw(requestParameters: ChatApiMessagesSetReactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling messagesSetReaction().'
            );
        }

        if (requestParameters['setRemoveReactionRequest'] == null) {
            throw new runtime.RequiredError(
                'setRemoveReactionRequest',
                'Required parameter "setRemoveReactionRequest" was null or undefined when calling messagesSetReaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/messages/{id}/set-reaction/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetRemoveReactionRequestToJSON(requestParameters['setRemoveReactionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async messagesSetReaction(requestParameters: ChatApiMessagesSetReactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.messagesSetReactionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async pinMessageRaw(requestParameters: ChatApiPinMessageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['pinMessageRequest'] == null) {
            throw new runtime.RequiredError(
                'pinMessageRequest',
                'Required parameter "pinMessageRequest" was null or undefined when calling pinMessage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/pin_message/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PinMessageRequestToJSON(requestParameters['pinMessageRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pinMessage(requestParameters: ChatApiPinMessageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pinMessageRaw(requestParameters, initOverrides);
    }

    /**
     * list of possible reactions to a message  [     {\"code\": \"U+1F44D\"},     {\"code\": ...,},     ... ]
     */
    async reactionsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Reactions>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/reactions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReactionsFromJSON));
    }

    /**
     * list of possible reactions to a message  [     {\"code\": \"U+1F44D\"},     {\"code\": ...,},     ... ]
     */
    async reactionsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Reactions>> {
        const response = await this.reactionsListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async reportMessageRaw(requestParameters: ChatApiReportMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['reportRequest'] == null) {
            throw new runtime.RequiredError(
                'reportRequest',
                'Required parameter "reportRequest" was null or undefined when calling reportMessage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/report_message/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportRequestToJSON(requestParameters['reportRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportMessage(requestParameters: ChatApiReportMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportMessageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async requestChangeNicknameRaw(requestParameters: ChatApiRequestChangeNicknameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['requestChangeNicknameRequest'] == null) {
            throw new runtime.RequiredError(
                'requestChangeNicknameRequest',
                'Required parameter "requestChangeNicknameRequest" was null or undefined when calling requestChangeNickname().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/request_change_nickname/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestChangeNicknameRequestToJSON(requestParameters['requestChangeNicknameRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestChangeNickname(requestParameters: ChatApiRequestChangeNicknameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestChangeNicknameRaw(requestParameters, initOverrides);
    }

    /**
     */
    async retrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Chat>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatFromJSON(jsonValue));
    }

    /**
     */
    async retrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Chat> {
        const response = await this.retrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async unbanRaw(requestParameters: ChatApiUnbanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['chatUnbanRequest'] == null) {
            throw new runtime.RequiredError(
                'chatUnbanRequest',
                'Required parameter "chatUnbanRequest" was null or undefined when calling unban().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/unban/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatUnbanRequestToJSON(requestParameters['chatUnbanRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unban(requestParameters: ChatApiUnbanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unbanRaw(requestParameters, initOverrides);
    }

    /**
     */
    async unpinMessageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/unpin_message/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unpinMessage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unpinMessageRaw(initOverrides);
    }

    /**
     */
    async updatePartialUpdateRaw(requestParameters: ChatApiUpdatePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatSettingsUpdate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/update/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedChatSettingsUpdateRequestToJSON(requestParameters['patchedChatSettingsUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatSettingsUpdateFromJSON(jsonValue));
    }

    /**
     */
    async updatePartialUpdate(requestParameters: ChatApiUpdatePartialUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatSettingsUpdate> {
        const response = await this.updatePartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUpdateRaw(requestParameters: ChatApiUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatSettingsUpdate>> {
        if (requestParameters['chatSettingsUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'chatSettingsUpdateRequest',
                'Required parameter "chatSettingsUpdateRequest" was null or undefined when calling updateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/update/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChatSettingsUpdateRequestToJSON(requestParameters['chatSettingsUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatSettingsUpdateFromJSON(jsonValue));
    }

    /**
     */
    async updateUpdate(requestParameters: ChatApiUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatSettingsUpdate> {
        const response = await this.updateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userRetrieveRaw(requestParameters: ChatApiUserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chat/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     */
    async userRetrieve(requestParameters: ChatApiUserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfo> {
        const response = await this.userRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
