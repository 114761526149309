// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BossBattleProfile
 */
export interface BossBattleProfile {
    /**
     * 
     * @type {number}
     * @memberof BossBattleProfile
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof BossBattleProfile
     */
    damage: number;
}

/**
 * Check if a given object implements the BossBattleProfile interface.
 */
export function instanceOfBossBattleProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "damage" in value;

    return isInstance;
}

export function BossBattleProfileFromJSON(json: any): BossBattleProfile {
    return BossBattleProfileFromJSONTyped(json, false);
}

export function BossBattleProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): BossBattleProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balance': json['balance'],
        'damage': json['damage'],
    };
}

export function BossBattleProfileToJSON(value?: BossBattleProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balance': value.balance,
        'damage': value.damage,
    };
}

