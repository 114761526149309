// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopUser } from './TopUser';
import {
    TopUserFromJSON,
    TopUserFromJSONTyped,
    TopUserToJSON,
} from './TopUser';

/**
 * 
 * @export
 * @interface BossBattleTopUsersResponse
 */
export interface BossBattleTopUsersResponse {
    /**
     * 
     * @type {number}
     * @memberof BossBattleTopUsersResponse
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof BossBattleTopUsersResponse
     */
    next: string | null;
    /**
     * 
     * @type {string}
     * @memberof BossBattleTopUsersResponse
     */
    previous: string | null;
    /**
     * 
     * @type {Array<TopUser>}
     * @memberof BossBattleTopUsersResponse
     */
    results: Array<TopUser>;
}

/**
 * Check if a given object implements the BossBattleTopUsersResponse interface.
 */
export function instanceOfBossBattleTopUsersResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "next" in value;
    isInstance = isInstance && "previous" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function BossBattleTopUsersResponseFromJSON(json: any): BossBattleTopUsersResponse {
    return BossBattleTopUsersResponseFromJSONTyped(json, false);
}

export function BossBattleTopUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BossBattleTopUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'],
        'previous': json['previous'],
        'results': ((json['results'] as Array<any>).map(TopUserFromJSON)),
    };
}

export function BossBattleTopUsersResponseToJSON(value?: BossBattleTopUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(TopUserToJSON)),
    };
}

