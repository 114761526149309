// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WithdrawalStatusEnum } from './WithdrawalStatusEnum';
import {
    WithdrawalStatusEnumFromJSON,
    WithdrawalStatusEnumFromJSONTyped,
    WithdrawalStatusEnumToJSON,
} from './WithdrawalStatusEnum';

/**
 * 
 * @export
 * @interface RobloxWithdrawalTyped
 */
export interface RobloxWithdrawalTyped {
    /**
     * 
     * @type {number}
     * @memberof RobloxWithdrawalTyped
     */
    amountRobux: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWithdrawalTyped
     */
    passLink: string;
    /**
     * 
     * @type {WithdrawalStatusEnum}
     * @memberof RobloxWithdrawalTyped
     */
    status: WithdrawalStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof RobloxWithdrawalTyped
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof RobloxWithdrawalTyped
     */
    cancelComment: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobloxWithdrawalTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the RobloxWithdrawalTyped interface.
 */
export function instanceOfRobloxWithdrawalTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amountRobux" in value;
    isInstance = isInstance && "passLink" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "cancelComment" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function RobloxWithdrawalTypedFromJSON(json: any): RobloxWithdrawalTyped {
    return RobloxWithdrawalTypedFromJSONTyped(json, false);
}

export function RobloxWithdrawalTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RobloxWithdrawalTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountRobux': json['amount_robux'],
        'passLink': json['pass_link'],
        'status': WithdrawalStatusEnumFromJSON(json['status']),
        'created': (new Date(json['created'])),
        'cancelComment': json['cancel_comment'],
        'resourcetype': json['resourcetype'],
    };
}

export function RobloxWithdrawalTypedToJSON(value?: RobloxWithdrawalTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_robux': value.amountRobux,
        'pass_link': value.passLink,
        'status': WithdrawalStatusEnumToJSON(value.status),
        'created': (value.created.toISOString()),
        'cancel_comment': value.cancelComment,
        'resourcetype': value.resourcetype,
    };
}

