// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ReadDescriptionRequest,
  TaskProgress,
} from '../models/index';
import {
    ReadDescriptionRequestFromJSON,
    ReadDescriptionRequestToJSON,
    TaskProgressFromJSON,
    TaskProgressToJSON,
} from '../models/index';

export interface QuestsApiReadCaseDescriptionCreateRequest {
    readDescriptionRequest: ReadDescriptionRequest;
}

export interface QuestsApiTaskProgressListRequest {
    slug: string;
}

/**
 * 
 */
export class QuestsApi extends runtime.BaseAPI {

    /**
     */
    async readCaseDescriptionCreateRaw(requestParameters: QuestsApiReadCaseDescriptionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['readDescriptionRequest'] == null) {
            throw new runtime.RequiredError(
                'readDescriptionRequest',
                'Required parameter "readDescriptionRequest" was null or undefined when calling readCaseDescriptionCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/quests/read-case-description/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReadDescriptionRequestToJSON(requestParameters['readDescriptionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async readCaseDescriptionCreate(requestParameters: QuestsApiReadCaseDescriptionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.readCaseDescriptionCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async taskProgressListRaw(requestParameters: QuestsApiTaskProgressListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TaskProgress>>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling taskProgressList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/quests/{slug}/task-progress/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskProgressFromJSON));
    }

    /**
     */
    async taskProgressList(requestParameters: QuestsApiTaskProgressListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TaskProgress>> {
        const response = await this.taskProgressListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
