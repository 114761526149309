// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IsoEnum } from './IsoEnum';
import {
    IsoEnumFromJSON,
    IsoEnumFromJSONTyped,
    IsoEnumToJSON,
} from './IsoEnum';

/**
 * 
 * @export
 * @interface TranslationLang
 */
export interface TranslationLang {
    /**
     * 
     * @type {number}
     * @memberof TranslationLang
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationLang
     */
    name: string;
    /**
     * 
     * @type {IsoEnum}
     * @memberof TranslationLang
     */
    iso: IsoEnum;
}

/**
 * Check if a given object implements the TranslationLang interface.
 */
export function instanceOfTranslationLang(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "iso" in value;

    return isInstance;
}

export function TranslationLangFromJSON(json: any): TranslationLang {
    return TranslationLangFromJSONTyped(json, false);
}

export function TranslationLangFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranslationLang {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'iso': IsoEnumFromJSON(json['iso']),
    };
}

export function TranslationLangToJSON(value?: TranslationLang | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'iso': IsoEnumToJSON(value.iso),
    };
}

