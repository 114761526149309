<template>
  <FlagProvider :unleash-client="$unleashClient">
    <NuxtLayout name="default">
      <div class="page-error">
        <div class="code">{{ error?.statusCode }}</div>
        <div
          class="message"
          @dblclick="handleDblclick"
        >
          {{
            error?.statusCode === 404
              ? t("page404.text")
              : t("errors.any_other")
          }}
        </div>
        <div v-if="showStack">
          {{ error?.stack }}
        </div>
      </div>
    </NuxtLayout>
  </FlagProvider>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";
import type { PlatformName } from "~/types/Platform";
import { useAppColor } from "~/composables/app/useAppColor";
import { FlagProvider } from "@unleash/proxy-client-vue";

console.log(useError());

const { t } = useI18n();
const userStore = useUserStore();
const { $unleashClient } = useNuxtApp();
const showStack = ref(false);
const props = defineProps({
  error: Object as () => NuxtError,
});
const platformStore = usePlatformStore();
const { error } = toRefs(props);

useSeoMeta({
  title: `Bulldrop ${error && error.value ? "| " + error.value.statusCode : ""}`,
  description: "",
  keywords: "",
  ogTitle: "",
  ogDescription: "",
});

const handleDblclick = () => {
  if (userStore.userIsAuthorized && userStore.session.user?.isStaff) {
    showStack.value = true;
  }
};

const getPlatformFromUrl = (url: string): PlatformName => {
  const platform = Object.keys(platformStore.ALL_PLATFORMS_DATA).find(
    (platform) => {
      if (url.includes(platform)) {
        return true;
      }
    },
  );

  if (platform) {
    return platform as PlatformName;
  }

  return platformStore.DEFAULT_PLATFORM;
};

const setStyles = () => {
  const errorVal = useError().value;
  const route = useRoute();

  if (errorVal) {
    const platformName = getPlatformFromUrl(route.path);
    if (platformName) {
      const platform = platformStore.ALL_PLATFORMS_DATA[platformName];

      useAppColor({ mainColor: platform.mainColor });
      if (isServer()) {
        useHead({
          bodyAttrs: {
            class: platform.className,
          },
        });
      } else {
        if (document.body.classList.contains(platform.className)) return;
        for (const platformName in platformStore.ALL_PLATFORMS_DATA) {
          const platfromData =
            platformStore.ALL_PLATFORMS_DATA[platformName as PlatformName];
          if (document.body.classList.contains(platfromData.className)) {
            document.body.classList.remove(platfromData.className);
          }
        }
        document.body.classList.add(platform.className);
      }
    }
  }
};

await userStore.handleVisit();
setStyles();
</script>

<style lang="scss" scoped>
.page-error {
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: var(--general-white);
}

.code,
.message {
  line-height: 1;
  text-align: center;
}

.code {
  font-size: 10rem;

  @media screen and (max-width: 600px) {
    font-size: 5rem;
  }
}

.message {
  font-size: 1.25rem;

  @media screen and (max-width: 600px) {
    font-size: 1rem;
  }
}
</style>
