// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Subject } from './Subject';
import {
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';
import type { TelegramCaseConditions } from './TelegramCaseConditions';
import {
    TelegramCaseConditionsFromJSON,
    TelegramCaseConditionsFromJSONTyped,
    TelegramCaseConditionsToJSON,
} from './TelegramCaseConditions';

/**
 * 
 * @export
 * @interface TelegramCase
 */
export interface TelegramCase {
    /**
     * 
     * @type {string}
     * @memberof TelegramCase
     */
    img: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramCase
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramCase
     */
    location: string;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof TelegramCase
     */
    readonly subjects: Array<Subject> | null;
    /**
     * 
     * @type {string}
     * @memberof TelegramCase
     */
    readonly canOpenAfter: string | null;
    /**
     * 
     * @type {TelegramCaseConditions}
     * @memberof TelegramCase
     */
    readonly conditions: TelegramCaseConditions | null;
}

/**
 * Check if a given object implements the TelegramCase interface.
 */
export function instanceOfTelegramCase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "subjects" in value;
    isInstance = isInstance && "canOpenAfter" in value;
    isInstance = isInstance && "conditions" in value;

    return isInstance;
}

export function TelegramCaseFromJSON(json: any): TelegramCase {
    return TelegramCaseFromJSONTyped(json, false);
}

export function TelegramCaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelegramCase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'img': json['img'],
        'name': json['name'],
        'location': json['location'],
        'subjects': (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(SubjectFromJSON)),
        'canOpenAfter': json['can_open_after'],
        'conditions': TelegramCaseConditionsFromJSON(json['conditions']),
    };
}

export function TelegramCaseToJSON(value?: TelegramCase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'img': value.img,
        'location': value.location,
    };
}

