// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenshinWithdrawal } from './GenshinWithdrawal';
import {
    GenshinWithdrawalFromJSON,
    GenshinWithdrawalFromJSONTyped,
    GenshinWithdrawalToJSON,
} from './GenshinWithdrawal';

/**
 * 
 * @export
 * @interface PaginatedGenshinWithdrawalList
 */
export interface PaginatedGenshinWithdrawalList {
    /**
     * 
     * @type {string}
     * @memberof PaginatedGenshinWithdrawalList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedGenshinWithdrawalList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<GenshinWithdrawal>}
     * @memberof PaginatedGenshinWithdrawalList
     */
    results: Array<GenshinWithdrawal>;
}

/**
 * Check if a given object implements the PaginatedGenshinWithdrawalList interface.
 */
export function instanceOfPaginatedGenshinWithdrawalList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function PaginatedGenshinWithdrawalListFromJSON(json: any): PaginatedGenshinWithdrawalList {
    return PaginatedGenshinWithdrawalListFromJSONTyped(json, false);
}

export function PaginatedGenshinWithdrawalListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedGenshinWithdrawalList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(GenshinWithdrawalFromJSON)),
    };
}

export function PaginatedGenshinWithdrawalListToJSON(value?: PaginatedGenshinWithdrawalList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(GenshinWithdrawalToJSON)),
    };
}

