// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VkCaseConditions
 */
export interface VkCaseConditions {
    /**
     * 
     * @type {boolean}
     * @memberof VkCaseConditions
     */
    isVkAccountPresent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VkCaseConditions
     */
    isVkGroupMember: boolean;
}

/**
 * Check if a given object implements the VkCaseConditions interface.
 */
export function instanceOfVkCaseConditions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isVkAccountPresent" in value;
    isInstance = isInstance && "isVkGroupMember" in value;

    return isInstance;
}

export function VkCaseConditionsFromJSON(json: any): VkCaseConditions {
    return VkCaseConditionsFromJSONTyped(json, false);
}

export function VkCaseConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VkCaseConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isVkAccountPresent': json['is_vk_account_present'],
        'isVkGroupMember': json['is_vk_group_member'],
    };
}

export function VkCaseConditionsToJSON(value?: VkCaseConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_vk_account_present': value.isVkAccountPresent,
        'is_vk_group_member': value.isVkGroupMember,
    };
}

