// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatMessageRoomEnum } from './ChatMessageRoomEnum';
import {
    ChatMessageRoomEnumFromJSON,
    ChatMessageRoomEnumFromJSONTyped,
    ChatMessageRoomEnumToJSON,
} from './ChatMessageRoomEnum';
import type { PlatformEnum } from './PlatformEnum';
import {
    PlatformEnumFromJSON,
    PlatformEnumFromJSONTyped,
    PlatformEnumToJSON,
} from './PlatformEnum';

/**
 * 
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {number}
     * @memberof ChatMessage
     */
    readonly id: number;
    /**
     * 
     * @type {ChatMessageRoomEnum}
     * @memberof ChatMessage
     */
    room: ChatMessageRoomEnum;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    readonly deleted: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessage
     */
    readonly deletedByCascade: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    messageText: string;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    roomId: string | null;
    /**
     * 
     * @type {PlatformEnum}
     * @memberof ChatMessage
     */
    game: PlatformEnum;
    /**
     * 
     * @type {any}
     * @memberof ChatMessage
     */
    reactions: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessage
     */
    isReported: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChatMessage
     */
    readonly messageSender: number;
}

/**
 * Check if a given object implements the ChatMessage interface.
 */
export function instanceOfChatMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "room" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "deletedByCascade" in value;
    isInstance = isInstance && "messageText" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "roomId" in value;
    isInstance = isInstance && "game" in value;
    isInstance = isInstance && "reactions" in value;
    isInstance = isInstance && "isReported" in value;
    isInstance = isInstance && "messageSender" in value;

    return isInstance;
}

export function ChatMessageFromJSON(json: any): ChatMessage {
    return ChatMessageFromJSONTyped(json, false);
}

export function ChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'room': ChatMessageRoomEnumFromJSON(json['room']),
        'deleted': (json['deleted'] === null ? null : new Date(json['deleted'])),
        'deletedByCascade': json['deleted_by_cascade'],
        'messageText': json['message_text'],
        'createdAt': (new Date(json['created_at'])),
        'roomId': json['room_id'],
        'game': PlatformEnumFromJSON(json['game']),
        'reactions': json['reactions'],
        'isReported': json['is_reported'],
        'messageSender': json['message_sender'],
    };
}

export function ChatMessageToJSON(value?: ChatMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room': ChatMessageRoomEnumToJSON(value.room),
        'message_text': value.messageText,
        'created_at': (value.createdAt.toISOString()),
        'room_id': value.roomId,
        'game': PlatformEnumToJSON(value.game),
        'reactions': value.reactions,
        'is_reported': value.isReported,
    };
}

