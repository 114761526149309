// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `percent_on_buy` - Процент от пополнения
 * * `fix` - Фикс. бонус
 * * `subject` - Бесплатный предмет
 * * `case` - Бесплатное открытие кейса
 * * `battle_pass` - Очки BattlePass
 * * `tickets` - Билеты лотереи
 * * `exchanger` - Обменник
 * * `boss_battle_attack_points` - Очки атаки для Битвы с боссом
 * * `empty` - пустой
 * @export
 */
export const PromoCodeTypeEnum = {
    PercentOnBuy: 'percent_on_buy',
    Fix: 'fix',
    Subject: 'subject',
    Case: 'case',
    BattlePass: 'battle_pass',
    Tickets: 'tickets',
    Exchanger: 'exchanger',
    BossBattleAttackPoints: 'boss_battle_attack_points',
    Empty: 'empty'
} as const;
export type PromoCodeTypeEnum = typeof PromoCodeTypeEnum[keyof typeof PromoCodeTypeEnum];


export function PromoCodeTypeEnumFromJSON(json: any): PromoCodeTypeEnum {
    return PromoCodeTypeEnumFromJSONTyped(json, false);
}

export function PromoCodeTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromoCodeTypeEnum {
    return json as PromoCodeTypeEnum;
}

export function PromoCodeTypeEnumToJSON(value?: PromoCodeTypeEnum | null): any {
    return value as any;
}

