// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NicknameDecorationsEnum } from './NicknameDecorationsEnum';
import {
    NicknameDecorationsEnumFromJSON,
    NicknameDecorationsEnumFromJSONTyped,
    NicknameDecorationsEnumToJSON,
} from './NicknameDecorationsEnum';
import type { StandoffSubjectQualityEnum } from './StandoffSubjectQualityEnum';
import {
    StandoffSubjectQualityEnumFromJSON,
    StandoffSubjectQualityEnumFromJSONTyped,
    StandoffSubjectQualityEnumToJSON,
} from './StandoffSubjectQualityEnum';

/**
 * 
 * @export
 * @interface NicknameDecorationLoot
 */
export interface NicknameDecorationLoot {
    /**
     * 
     * @type {NicknameDecorationsEnum}
     * @memberof NicknameDecorationLoot
     */
    decoration: NicknameDecorationsEnum;
    /**
     * 
     * @type {string}
     * @memberof NicknameDecorationLoot
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NicknameDecorationLoot
     */
    img: string | null;
    /**
     * 
     * @type {number}
     * @memberof NicknameDecorationLoot
     */
    chance: number;
    /**
     * 
     * @type {StandoffSubjectQualityEnum}
     * @memberof NicknameDecorationLoot
     */
    qualityColor: StandoffSubjectQualityEnum;
}

/**
 * Check if a given object implements the NicknameDecorationLoot interface.
 */
export function instanceOfNicknameDecorationLoot(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "decoration" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "chance" in value;
    isInstance = isInstance && "qualityColor" in value;

    return isInstance;
}

export function NicknameDecorationLootFromJSON(json: any): NicknameDecorationLoot {
    return NicknameDecorationLootFromJSONTyped(json, false);
}

export function NicknameDecorationLootFromJSONTyped(json: any, ignoreDiscriminator: boolean): NicknameDecorationLoot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decoration': NicknameDecorationsEnumFromJSON(json['decoration']),
        'name': json['name'],
        'img': json['img'],
        'chance': json['chance'],
        'qualityColor': StandoffSubjectQualityEnumFromJSON(json['quality_color']),
    };
}

export function NicknameDecorationLootToJSON(value?: NicknameDecorationLoot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decoration': NicknameDecorationsEnumToJSON(value.decoration),
        'name': value.name,
        'img': value.img,
        'chance': value.chance,
        'quality_color': StandoffSubjectQualityEnumToJSON(value.qualityColor),
    };
}

