// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StandoffSubject } from './StandoffSubject';
import {
    StandoffSubjectFromJSON,
    StandoffSubjectFromJSONTyped,
    StandoffSubjectToJSON,
} from './StandoffSubject';

/**
 * 
 * @export
 * @interface BloggerBattleReward
 */
export interface BloggerBattleReward {
    /**
     * 
     * @type {number}
     * @memberof BloggerBattleReward
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof BloggerBattleReward
     */
    end: number;
    /**
     * 
     * @type {StandoffSubject}
     * @memberof BloggerBattleReward
     */
    reward: StandoffSubject;
}

/**
 * Check if a given object implements the BloggerBattleReward interface.
 */
export function instanceOfBloggerBattleReward(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "reward" in value;

    return isInstance;
}

export function BloggerBattleRewardFromJSON(json: any): BloggerBattleReward {
    return BloggerBattleRewardFromJSONTyped(json, false);
}

export function BloggerBattleRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): BloggerBattleReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': json['start'],
        'end': json['end'],
        'reward': StandoffSubjectFromJSON(json['reward']),
    };
}

export function BloggerBattleRewardToJSON(value?: BloggerBattleReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'end': value.end,
        'reward': StandoffSubjectToJSON(value.reward),
    };
}

