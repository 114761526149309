// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenshinServerEnum } from './GenshinServerEnum';
import {
    GenshinServerEnumFromJSON,
    GenshinServerEnumFromJSONTyped,
    GenshinServerEnumToJSON,
} from './GenshinServerEnum';
import type { WithdrawalStatusEnum } from './WithdrawalStatusEnum';
import {
    WithdrawalStatusEnumFromJSON,
    WithdrawalStatusEnumFromJSONTyped,
    WithdrawalStatusEnumToJSON,
} from './WithdrawalStatusEnum';

/**
 * 
 * @export
 * @interface GenshinWithdrawalTyped
 */
export interface GenshinWithdrawalTyped {
    /**
     * 
     * @type {number}
     * @memberof GenshinWithdrawalTyped
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof GenshinWithdrawalTyped
     */
    amountBullcoins: number;
    /**
     * 
     * @type {number}
     * @memberof GenshinWithdrawalTyped
     */
    amountGems: number;
    /**
     * 
     * @type {number}
     * @memberof GenshinWithdrawalTyped
     */
    amountMoons: number;
    /**
     * 
     * @type {WithdrawalStatusEnum}
     * @memberof GenshinWithdrawalTyped
     */
    status: WithdrawalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GenshinWithdrawalTyped
     */
    genshinUid: string;
    /**
     * 
     * @type {GenshinServerEnum}
     * @memberof GenshinWithdrawalTyped
     */
    genshinServer: GenshinServerEnum;
    /**
     * 
     * @type {Date}
     * @memberof GenshinWithdrawalTyped
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof GenshinWithdrawalTyped
     */
    cancelComment: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenshinWithdrawalTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the GenshinWithdrawalTyped interface.
 */
export function instanceOfGenshinWithdrawalTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amountBullcoins" in value;
    isInstance = isInstance && "amountGems" in value;
    isInstance = isInstance && "amountMoons" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "genshinUid" in value;
    isInstance = isInstance && "genshinServer" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "cancelComment" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function GenshinWithdrawalTypedFromJSON(json: any): GenshinWithdrawalTyped {
    return GenshinWithdrawalTypedFromJSONTyped(json, false);
}

export function GenshinWithdrawalTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenshinWithdrawalTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amountBullcoins': json['amount_bullcoins'],
        'amountGems': json['amount_gems'],
        'amountMoons': json['amount_moons'],
        'status': WithdrawalStatusEnumFromJSON(json['status']),
        'genshinUid': json['genshin_uid'],
        'genshinServer': GenshinServerEnumFromJSON(json['genshin_server']),
        'created': (new Date(json['created'])),
        'cancelComment': json['cancel_comment'],
        'resourcetype': json['resourcetype'],
    };
}

export function GenshinWithdrawalTypedToJSON(value?: GenshinWithdrawalTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_bullcoins': value.amountBullcoins,
        'amount_gems': value.amountGems,
        'amount_moons': value.amountMoons,
        'status': WithdrawalStatusEnumToJSON(value.status),
        'genshin_uid': value.genshinUid,
        'genshin_server': GenshinServerEnumToJSON(value.genshinServer),
        'created': (value.created.toISOString()),
        'cancel_comment': value.cancelComment,
        'resourcetype': value.resourcetype,
    };
}

