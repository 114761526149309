// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AutoCompleteStandoffWithdrawal,
  BlessingOfTheWelkinMoon,
  CalculatePUBGPacksRequest,
  CalculatePacksRequest,
  GenshinGemPack,
  GenshinMinWithdrawal,
  GenshinWithdrawal,
  MakeGenshinWithdrawalRequest,
  MakePUBGWithdrawalRequest,
  MakeRobloxWithdrawalRequest,
  MakeWithdrawalRequest,
  PUBGMinWithdrawal,
  PUBGPack,
  PUBGWithdrawal,
  PaginatedGenshinWithdrawalList,
  PaginatedInventoryList,
  PaginatedPUBGWithdrawalList,
  PaginatedRobloxWithdrawalList,
  PaginatedSkinsbackWithdrawalFullList,
  PaginatedStandoffWithdrawalReadList,
  PaginatedSubjectList,
  PreparedGenshinWithdrawal,
  PreparedMooGoldWithdrawal,
  RobloxSettings,
  RobloxWithdrawal,
  RobloxWithdrawalCalculateRequest,
  RobloxWithdrawalCalculateResponse,
  SkinsbackInfo,
  SkinsbackWithdrawal,
  WithdrawalRestrictions,
  WithdrawalSettings,
} from '../models/index';
import {
    AutoCompleteStandoffWithdrawalFromJSON,
    AutoCompleteStandoffWithdrawalToJSON,
    BlessingOfTheWelkinMoonFromJSON,
    BlessingOfTheWelkinMoonToJSON,
    CalculatePUBGPacksRequestFromJSON,
    CalculatePUBGPacksRequestToJSON,
    CalculatePacksRequestFromJSON,
    CalculatePacksRequestToJSON,
    GenshinGemPackFromJSON,
    GenshinGemPackToJSON,
    GenshinMinWithdrawalFromJSON,
    GenshinMinWithdrawalToJSON,
    GenshinWithdrawalFromJSON,
    GenshinWithdrawalToJSON,
    MakeGenshinWithdrawalRequestFromJSON,
    MakeGenshinWithdrawalRequestToJSON,
    MakePUBGWithdrawalRequestFromJSON,
    MakePUBGWithdrawalRequestToJSON,
    MakeRobloxWithdrawalRequestFromJSON,
    MakeRobloxWithdrawalRequestToJSON,
    MakeWithdrawalRequestFromJSON,
    MakeWithdrawalRequestToJSON,
    PUBGMinWithdrawalFromJSON,
    PUBGMinWithdrawalToJSON,
    PUBGPackFromJSON,
    PUBGPackToJSON,
    PUBGWithdrawalFromJSON,
    PUBGWithdrawalToJSON,
    PaginatedGenshinWithdrawalListFromJSON,
    PaginatedGenshinWithdrawalListToJSON,
    PaginatedInventoryListFromJSON,
    PaginatedInventoryListToJSON,
    PaginatedPUBGWithdrawalListFromJSON,
    PaginatedPUBGWithdrawalListToJSON,
    PaginatedRobloxWithdrawalListFromJSON,
    PaginatedRobloxWithdrawalListToJSON,
    PaginatedSkinsbackWithdrawalFullListFromJSON,
    PaginatedSkinsbackWithdrawalFullListToJSON,
    PaginatedStandoffWithdrawalReadListFromJSON,
    PaginatedStandoffWithdrawalReadListToJSON,
    PaginatedSubjectListFromJSON,
    PaginatedSubjectListToJSON,
    PreparedGenshinWithdrawalFromJSON,
    PreparedGenshinWithdrawalToJSON,
    PreparedMooGoldWithdrawalFromJSON,
    PreparedMooGoldWithdrawalToJSON,
    RobloxSettingsFromJSON,
    RobloxSettingsToJSON,
    RobloxWithdrawalFromJSON,
    RobloxWithdrawalToJSON,
    RobloxWithdrawalCalculateRequestFromJSON,
    RobloxWithdrawalCalculateRequestToJSON,
    RobloxWithdrawalCalculateResponseFromJSON,
    RobloxWithdrawalCalculateResponseToJSON,
    SkinsbackInfoFromJSON,
    SkinsbackInfoToJSON,
    SkinsbackWithdrawalFromJSON,
    SkinsbackWithdrawalToJSON,
    WithdrawalRestrictionsFromJSON,
    WithdrawalRestrictionsToJSON,
    WithdrawalSettingsFromJSON,
    WithdrawalSettingsToJSON,
} from '../models/index';

export interface WithdrawalsApiGenshinCalculatePacksRequest {
    calculatePacksRequest: CalculatePacksRequest;
}

export interface WithdrawalsApiGenshinListRequest {
    cursor?: string;
    pageSize?: number;
}

export interface WithdrawalsApiGenshinMakeRequest {
    makeGenshinWithdrawalRequest: MakeGenshinWithdrawalRequest;
}

export interface WithdrawalsApiPubgCalculatePacksRequest {
    calculatePUBGPacksRequest: CalculatePUBGPacksRequest;
}

export interface WithdrawalsApiPubgListRequest {
    cursor?: string;
    pageSize?: number;
}

export interface WithdrawalsApiPubgMakeRequest {
    makePUBGWithdrawalRequest: MakePUBGWithdrawalRequest;
}

export interface WithdrawalsApiRobloxCalculateCreateRequest {
    robloxWithdrawalCalculateRequest: RobloxWithdrawalCalculateRequest;
}

export interface WithdrawalsApiRobloxListRequest {
    page?: number;
}

export interface WithdrawalsApiRobloxMakeRequest {
    makeRobloxWithdrawalRequest: MakeRobloxWithdrawalRequest;
}

export interface WithdrawalsApiSkinsbackAvailableSkinsInventoryListRequest {
    isAvailable?: boolean;
    limit?: number;
    name?: string;
    offset?: number;
    ordering?: string;
    priceFrom?: number;
    priceTo?: number;
    qualityColor?: SkinsbackAvailableSkinsInventoryListQualityColorEnum;
    type?: string;
    wear?: SkinsbackAvailableSkinsInventoryListWearEnum;
}

export interface WithdrawalsApiSkinsbackAvailableSkinsMarketListRequest {
    isAvailable?: boolean;
    limit?: number;
    name?: string;
    offset?: number;
    ordering?: string;
    priceFrom?: number;
    priceTo?: number;
    qualityColor?: SkinsbackAvailableSkinsMarketListQualityColorEnum;
    type?: string;
    wear?: SkinsbackAvailableSkinsMarketListWearEnum;
}

export interface WithdrawalsApiSkinsbackListRequest {
    cursor?: string;
    pageSize?: number;
}

export interface WithdrawalsApiSkinsbackMakeRequest {
    makeWithdrawalRequest: MakeWithdrawalRequest;
}

export interface WithdrawalsApiStandoffListRequest {
    page?: number;
}

export interface WithdrawalsApiStandoffMakeRequest {
    amountBullcoins: number;
    surplus: number;
    gameAvatar?: Blob;
    gameAccountId?: string | null;
}

/**
 * 
 */
export class WithdrawalsApi extends runtime.BaseAPI {

    /**
     */
    async genshinCalculatePacksRaw(requestParameters: WithdrawalsApiGenshinCalculatePacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreparedGenshinWithdrawal>> {
        if (requestParameters['calculatePacksRequest'] == null) {
            throw new runtime.RequiredError(
                'calculatePacksRequest',
                'Required parameter "calculatePacksRequest" was null or undefined when calling genshinCalculatePacks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/genshin/calculate-packs/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculatePacksRequestToJSON(requestParameters['calculatePacksRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreparedGenshinWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async genshinCalculatePacks(requestParameters: WithdrawalsApiGenshinCalculatePacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreparedGenshinWithdrawal> {
        const response = await this.genshinCalculatePacksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async genshinGemPacksListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GenshinGemPack>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/genshin/gem-packs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GenshinGemPackFromJSON));
    }

    /**
     */
    async genshinGemPacksList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GenshinGemPack>> {
        const response = await this.genshinGemPacksListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async genshinListRaw(requestParameters: WithdrawalsApiGenshinListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedGenshinWithdrawalList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/genshin/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedGenshinWithdrawalListFromJSON(jsonValue));
    }

    /**
     */
    async genshinList(requestParameters: WithdrawalsApiGenshinListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedGenshinWithdrawalList> {
        const response = await this.genshinListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async genshinMakeRaw(requestParameters: WithdrawalsApiGenshinMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenshinWithdrawal>> {
        if (requestParameters['makeGenshinWithdrawalRequest'] == null) {
            throw new runtime.RequiredError(
                'makeGenshinWithdrawalRequest',
                'Required parameter "makeGenshinWithdrawalRequest" was null or undefined when calling genshinMake().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/genshin/make/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakeGenshinWithdrawalRequestToJSON(requestParameters['makeGenshinWithdrawalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenshinWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async genshinMake(requestParameters: WithdrawalsApiGenshinMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenshinWithdrawal> {
        const response = await this.genshinMakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async genshinMinRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenshinMinWithdrawal>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/genshin/min/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenshinMinWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async genshinMinRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenshinMinWithdrawal> {
        const response = await this.genshinMinRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async genshinMoonRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlessingOfTheWelkinMoon>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/genshin/moon/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlessingOfTheWelkinMoonFromJSON(jsonValue));
    }

    /**
     */
    async genshinMoonRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlessingOfTheWelkinMoon> {
        const response = await this.genshinMoonRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async pubgCalculatePacksRaw(requestParameters: WithdrawalsApiPubgCalculatePacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreparedMooGoldWithdrawal>> {
        if (requestParameters['calculatePUBGPacksRequest'] == null) {
            throw new runtime.RequiredError(
                'calculatePUBGPacksRequest',
                'Required parameter "calculatePUBGPacksRequest" was null or undefined when calling pubgCalculatePacks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/pubg/calculate-packs/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculatePUBGPacksRequestToJSON(requestParameters['calculatePUBGPacksRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreparedMooGoldWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async pubgCalculatePacks(requestParameters: WithdrawalsApiPubgCalculatePacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreparedMooGoldWithdrawal> {
        const response = await this.pubgCalculatePacksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pubgListRaw(requestParameters: WithdrawalsApiPubgListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPUBGWithdrawalList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/pubg/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPUBGWithdrawalListFromJSON(jsonValue));
    }

    /**
     */
    async pubgList(requestParameters: WithdrawalsApiPubgListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPUBGWithdrawalList> {
        const response = await this.pubgListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pubgMakeRaw(requestParameters: WithdrawalsApiPubgMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PUBGWithdrawal>> {
        if (requestParameters['makePUBGWithdrawalRequest'] == null) {
            throw new runtime.RequiredError(
                'makePUBGWithdrawalRequest',
                'Required parameter "makePUBGWithdrawalRequest" was null or undefined when calling pubgMake().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/pubg/make/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakePUBGWithdrawalRequestToJSON(requestParameters['makePUBGWithdrawalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PUBGWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async pubgMake(requestParameters: WithdrawalsApiPubgMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PUBGWithdrawal> {
        const response = await this.pubgMakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pubgMinRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PUBGMinWithdrawal>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/pubg/min/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PUBGMinWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async pubgMinRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PUBGMinWithdrawal> {
        const response = await this.pubgMinRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async pubgPacksListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PUBGPack>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/pubg/packs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PUBGPackFromJSON));
    }

    /**
     */
    async pubgPacksList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PUBGPack>> {
        const response = await this.pubgPacksListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async restrictionsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalRestrictions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/restrictions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalRestrictionsFromJSON(jsonValue));
    }

    /**
     */
    async restrictionsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalRestrictions> {
        const response = await this.restrictionsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async robloxCalculateCreateRaw(requestParameters: WithdrawalsApiRobloxCalculateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RobloxWithdrawalCalculateResponse>> {
        if (requestParameters['robloxWithdrawalCalculateRequest'] == null) {
            throw new runtime.RequiredError(
                'robloxWithdrawalCalculateRequest',
                'Required parameter "robloxWithdrawalCalculateRequest" was null or undefined when calling robloxCalculateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/roblox/calculate/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RobloxWithdrawalCalculateRequestToJSON(requestParameters['robloxWithdrawalCalculateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RobloxWithdrawalCalculateResponseFromJSON(jsonValue));
    }

    /**
     */
    async robloxCalculateCreate(requestParameters: WithdrawalsApiRobloxCalculateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RobloxWithdrawalCalculateResponse> {
        const response = await this.robloxCalculateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async robloxListRaw(requestParameters: WithdrawalsApiRobloxListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRobloxWithdrawalList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/roblox/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRobloxWithdrawalListFromJSON(jsonValue));
    }

    /**
     */
    async robloxList(requestParameters: WithdrawalsApiRobloxListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRobloxWithdrawalList> {
        const response = await this.robloxListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async robloxMakeRaw(requestParameters: WithdrawalsApiRobloxMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RobloxWithdrawal>> {
        if (requestParameters['makeRobloxWithdrawalRequest'] == null) {
            throw new runtime.RequiredError(
                'makeRobloxWithdrawalRequest',
                'Required parameter "makeRobloxWithdrawalRequest" was null or undefined when calling robloxMake().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/roblox/make/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakeRobloxWithdrawalRequestToJSON(requestParameters['makeRobloxWithdrawalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RobloxWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async robloxMake(requestParameters: WithdrawalsApiRobloxMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RobloxWithdrawal> {
        const response = await this.robloxMakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async robloxSettingsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RobloxSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/roblox/settings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RobloxSettingsFromJSON(jsonValue));
    }

    /**
     */
    async robloxSettingsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RobloxSettings> {
        const response = await this.robloxSettingsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async skinsbackAvailableSkinsInventoryListRaw(requestParameters: WithdrawalsApiSkinsbackAvailableSkinsInventoryListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInventoryList>> {
        const queryParameters: any = {};

        if (requestParameters['isAvailable'] != null) {
            queryParameters['is_available'] = requestParameters['isAvailable'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['priceFrom'] != null) {
            queryParameters['price_from'] = requestParameters['priceFrom'];
        }

        if (requestParameters['priceTo'] != null) {
            queryParameters['price_to'] = requestParameters['priceTo'];
        }

        if (requestParameters['qualityColor'] != null) {
            queryParameters['quality_color'] = requestParameters['qualityColor'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['wear'] != null) {
            queryParameters['wear'] = requestParameters['wear'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/skinsback/available-skins/inventory/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInventoryListFromJSON(jsonValue));
    }

    /**
     */
    async skinsbackAvailableSkinsInventoryList(requestParameters: WithdrawalsApiSkinsbackAvailableSkinsInventoryListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInventoryList> {
        const response = await this.skinsbackAvailableSkinsInventoryListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async skinsbackAvailableSkinsMarketListRaw(requestParameters: WithdrawalsApiSkinsbackAvailableSkinsMarketListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedSubjectList>> {
        const queryParameters: any = {};

        if (requestParameters['isAvailable'] != null) {
            queryParameters['is_available'] = requestParameters['isAvailable'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['priceFrom'] != null) {
            queryParameters['price_from'] = requestParameters['priceFrom'];
        }

        if (requestParameters['priceTo'] != null) {
            queryParameters['price_to'] = requestParameters['priceTo'];
        }

        if (requestParameters['qualityColor'] != null) {
            queryParameters['quality_color'] = requestParameters['qualityColor'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['wear'] != null) {
            queryParameters['wear'] = requestParameters['wear'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/skinsback/available-skins/market/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSubjectListFromJSON(jsonValue));
    }

    /**
     */
    async skinsbackAvailableSkinsMarketList(requestParameters: WithdrawalsApiSkinsbackAvailableSkinsMarketListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedSubjectList> {
        const response = await this.skinsbackAvailableSkinsMarketListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async skinsbackListRaw(requestParameters: WithdrawalsApiSkinsbackListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedSkinsbackWithdrawalFullList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/skinsback/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSkinsbackWithdrawalFullListFromJSON(jsonValue));
    }

    /**
     */
    async skinsbackList(requestParameters: WithdrawalsApiSkinsbackListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedSkinsbackWithdrawalFullList> {
        const response = await this.skinsbackListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async skinsbackMakeRaw(requestParameters: WithdrawalsApiSkinsbackMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SkinsbackWithdrawal>> {
        if (requestParameters['makeWithdrawalRequest'] == null) {
            throw new runtime.RequiredError(
                'makeWithdrawalRequest',
                'Required parameter "makeWithdrawalRequest" was null or undefined when calling skinsbackMake().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/skinsback/make/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakeWithdrawalRequestToJSON(requestParameters['makeWithdrawalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkinsbackWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async skinsbackMake(requestParameters: WithdrawalsApiSkinsbackMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SkinsbackWithdrawal> {
        const response = await this.skinsbackMakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async skinsbackUserInfoRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SkinsbackInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/skinsback/user-info/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkinsbackInfoFromJSON(jsonValue));
    }

    /**
     */
    async skinsbackUserInfoRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SkinsbackInfo> {
        const response = await this.skinsbackUserInfoRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Это не автоподтверждение вывода, а подстановка данных по последнему выводу.
     */
    async standoffAutoCompleteRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoCompleteStandoffWithdrawal>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/standoff/auto-complete/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoCompleteStandoffWithdrawalFromJSON(jsonValue));
    }

    /**
     * Это не автоподтверждение вывода, а подстановка данных по последнему выводу.
     */
    async standoffAutoCompleteRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoCompleteStandoffWithdrawal> {
        const response = await this.standoffAutoCompleteRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async standoffListRaw(requestParameters: WithdrawalsApiStandoffListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedStandoffWithdrawalReadList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/standoff/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedStandoffWithdrawalReadListFromJSON(jsonValue));
    }

    /**
     */
    async standoffList(requestParameters: WithdrawalsApiStandoffListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedStandoffWithdrawalReadList> {
        const response = await this.standoffListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async standoffMakeRaw(requestParameters: WithdrawalsApiStandoffMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['amountBullcoins'] == null) {
            throw new runtime.RequiredError(
                'amountBullcoins',
                'Required parameter "amountBullcoins" was null or undefined when calling standoffMake().'
            );
        }

        if (requestParameters['surplus'] == null) {
            throw new runtime.RequiredError(
                'surplus',
                'Required parameter "surplus" was null or undefined when calling standoffMake().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['amountBullcoins'] != null) {
            formParams.append('amount_bullcoins', requestParameters['amountBullcoins'] as any);
        }

        if (requestParameters['gameAvatar'] != null) {
            formParams.append('game_avatar', requestParameters['gameAvatar'] as any);
        }

        if (requestParameters['gameAccountId'] != null) {
            formParams.append('game_account_id', requestParameters['gameAccountId'] as any);
        }

        if (requestParameters['surplus'] != null) {
            formParams.append('surplus', requestParameters['surplus'] as any);
        }

        const response = await this.request({
            path: `/api/withdrawals/standoff/make/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async standoffMake(requestParameters: WithdrawalsApiStandoffMakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.standoffMakeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async standoffSettingsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/withdrawals/standoff/settings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalSettingsFromJSON(jsonValue));
    }

    /**
     */
    async standoffSettingsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalSettings> {
        const response = await this.standoffSettingsRetrieveRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const SkinsbackAvailableSkinsInventoryListQualityColorEnum = {
    _4867ff: '4867ff',
    _71aeec: '71aeec',
    _9059ff: '9059ff',
    Aaaaaa: 'aaaaaa',
    D64bf3: 'd64bf3',
    Fe3852: 'fe3852',
    Ffc259: 'ffc259'
} as const;
export type SkinsbackAvailableSkinsInventoryListQualityColorEnum = typeof SkinsbackAvailableSkinsInventoryListQualityColorEnum[keyof typeof SkinsbackAvailableSkinsInventoryListQualityColorEnum];
/**
 * @export
 */
export const SkinsbackAvailableSkinsInventoryListWearEnum = {
    BattleScarred: 'Battle-Scarred',
    FactoryNew: 'Factory New',
    FieldTested: 'Field-Tested',
    MinimalWear: 'Minimal Wear',
    WellWorn: 'Well-Worn'
} as const;
export type SkinsbackAvailableSkinsInventoryListWearEnum = typeof SkinsbackAvailableSkinsInventoryListWearEnum[keyof typeof SkinsbackAvailableSkinsInventoryListWearEnum];
/**
 * @export
 */
export const SkinsbackAvailableSkinsMarketListQualityColorEnum = {
    _4867ff: '4867ff',
    _71aeec: '71aeec',
    _9059ff: '9059ff',
    Aaaaaa: 'aaaaaa',
    D64bf3: 'd64bf3',
    Fe3852: 'fe3852',
    Ffc259: 'ffc259'
} as const;
export type SkinsbackAvailableSkinsMarketListQualityColorEnum = typeof SkinsbackAvailableSkinsMarketListQualityColorEnum[keyof typeof SkinsbackAvailableSkinsMarketListQualityColorEnum];
/**
 * @export
 */
export const SkinsbackAvailableSkinsMarketListWearEnum = {
    BattleScarred: 'Battle-Scarred',
    FactoryNew: 'Factory New',
    FieldTested: 'Field-Tested',
    MinimalWear: 'Minimal Wear',
    WellWorn: 'Well-Worn'
} as const;
export type SkinsbackAvailableSkinsMarketListWearEnum = typeof SkinsbackAvailableSkinsMarketListWearEnum[keyof typeof SkinsbackAvailableSkinsMarketListWearEnum];
