// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LadderGameStatusEnum } from './LadderGameStatusEnum';
import {
    LadderGameStatusEnumFromJSON,
    LadderGameStatusEnumFromJSONTyped,
    LadderGameStatusEnumToJSON,
} from './LadderGameStatusEnum';
import type { ModeEnum } from './ModeEnum';
import {
    ModeEnumFromJSON,
    ModeEnumFromJSONTyped,
    ModeEnumToJSON,
} from './ModeEnum';
import type { UserShort } from './UserShort';
import {
    UserShortFromJSON,
    UserShortFromJSONTyped,
    UserShortToJSON,
} from './UserShort';

/**
 * 
 * @export
 * @interface StartGameOutput
 */
export interface StartGameOutput {
    /**
     * 
     * @type {string}
     * @memberof StartGameOutput
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof StartGameOutput
     */
    started: Date;
    /**
     * 
     * @type {number}
     * @memberof StartGameOutput
     */
    rate: number;
    /**
     * 
     * @type {number}
     * @memberof StartGameOutput
     */
    currentCoef: number;
    /**
     * 
     * @type {LadderGameStatusEnum}
     * @memberof StartGameOutput
     */
    status: LadderGameStatusEnum;
    /**
     * 
     * @type {ModeEnum}
     * @memberof StartGameOutput
     */
    mode: ModeEnum;
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof StartGameOutput
     */
    readonly rounds: Array<{ [key: string]: any; }>;
    /**
     * 
     * @type {UserShort}
     * @memberof StartGameOutput
     */
    gamer: UserShort;
    /**
     * 
     * @type {number}
     * @memberof StartGameOutput
     */
    totalRounds: number;
}

/**
 * Check if a given object implements the StartGameOutput interface.
 */
export function instanceOfStartGameOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "started" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "currentCoef" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "mode" in value;
    isInstance = isInstance && "rounds" in value;
    isInstance = isInstance && "gamer" in value;
    isInstance = isInstance && "totalRounds" in value;

    return isInstance;
}

export function StartGameOutputFromJSON(json: any): StartGameOutput {
    return StartGameOutputFromJSONTyped(json, false);
}

export function StartGameOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartGameOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'started': (new Date(json['started'])),
        'rate': json['rate'],
        'currentCoef': json['current_coef'],
        'status': LadderGameStatusEnumFromJSON(json['status']),
        'mode': ModeEnumFromJSON(json['mode']),
        'rounds': json['rounds'],
        'gamer': UserShortFromJSON(json['gamer']),
        'totalRounds': json['total_rounds'],
    };
}

export function StartGameOutputToJSON(value?: StartGameOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'started': (value.started.toISOString()),
        'rate': value.rate,
        'current_coef': value.currentCoef,
        'status': LadderGameStatusEnumToJSON(value.status),
        'mode': ModeEnumToJSON(value.mode),
        'gamer': UserShortToJSON(value.gamer),
        'total_rounds': value.totalRounds,
    };
}

