// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TranslationKey } from './TranslationKey';
import {
    TranslationKeyFromJSON,
    TranslationKeyFromJSONTyped,
    TranslationKeyToJSON,
} from './TranslationKey';
import type { TranslationLang } from './TranslationLang';
import {
    TranslationLangFromJSON,
    TranslationLangFromJSONTyped,
    TranslationLangToJSON,
} from './TranslationLang';

/**
 * 
 * @export
 * @interface FullTranslation
 */
export interface FullTranslation {
    /**
     * 
     * @type {number}
     * @memberof FullTranslation
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullTranslation
     */
    value: string;
    /**
     * 
     * @type {TranslationLang}
     * @memberof FullTranslation
     */
    language: TranslationLang;
    /**
     * 
     * @type {TranslationKey}
     * @memberof FullTranslation
     */
    key: TranslationKey;
}

/**
 * Check if a given object implements the FullTranslation interface.
 */
export function instanceOfFullTranslation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "key" in value;

    return isInstance;
}

export function FullTranslationFromJSON(json: any): FullTranslation {
    return FullTranslationFromJSONTyped(json, false);
}

export function FullTranslationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullTranslation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
        'language': TranslationLangFromJSON(json['language']),
        'key': TranslationKeyFromJSON(json['key']),
    };
}

export function FullTranslationToJSON(value?: FullTranslation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'value': value.value,
        'language': TranslationLangToJSON(value.language),
        'key': TranslationKeyToJSON(value.key),
    };
}

