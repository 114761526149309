// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CrashSettings
 */
export interface CrashSettings {
    /**
     * 
     * @type {number}
     * @memberof CrashSettings
     */
    minBet: number;
    /**
     * 
     * @type {number}
     * @memberof CrashSettings
     */
    maxBet: number | null;
}

/**
 * Check if a given object implements the CrashSettings interface.
 */
export function instanceOfCrashSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minBet" in value;
    isInstance = isInstance && "maxBet" in value;

    return isInstance;
}

export function CrashSettingsFromJSON(json: any): CrashSettings {
    return CrashSettingsFromJSONTyped(json, false);
}

export function CrashSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrashSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minBet': json['min_bet'],
        'maxBet': json['max_bet'],
    };
}

export function CrashSettingsToJSON(value?: CrashSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min_bet': value.minBet,
        'max_bet': value.maxBet,
    };
}

