// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GenshinWithdrawalTyped,
    instanceOfGenshinWithdrawalTyped,
    GenshinWithdrawalTypedFromJSON,
    GenshinWithdrawalTypedFromJSONTyped,
    GenshinWithdrawalTypedToJSON,
} from './GenshinWithdrawalTyped';
import {
    PUBGWithdrawalTyped,
    instanceOfPUBGWithdrawalTyped,
    PUBGWithdrawalTypedFromJSON,
    PUBGWithdrawalTypedFromJSONTyped,
    PUBGWithdrawalTypedToJSON,
} from './PUBGWithdrawalTyped';
import {
    RobloxWithdrawalTyped,
    instanceOfRobloxWithdrawalTyped,
    RobloxWithdrawalTypedFromJSON,
    RobloxWithdrawalTypedFromJSONTyped,
    RobloxWithdrawalTypedToJSON,
} from './RobloxWithdrawalTyped';
import {
    SkinsbackWithdrawalFullTyped,
    instanceOfSkinsbackWithdrawalFullTyped,
    SkinsbackWithdrawalFullTypedFromJSON,
    SkinsbackWithdrawalFullTypedFromJSONTyped,
    SkinsbackWithdrawalFullTypedToJSON,
} from './SkinsbackWithdrawalFullTyped';
import {
    StandoffWithdrawalTyped,
    instanceOfStandoffWithdrawalTyped,
    StandoffWithdrawalTypedFromJSON,
    StandoffWithdrawalTypedFromJSONTyped,
    StandoffWithdrawalTypedToJSON,
} from './StandoffWithdrawalTyped';

/**
 * @type Withdrawal
 * 
 * @export
 */
export type Withdrawal = { resourcetype: 'GenshinWithdrawal' } & GenshinWithdrawalTyped | { resourcetype: 'PUBGWithdrawal' } & PUBGWithdrawalTyped | { resourcetype: 'RobloxWithdrawal' } & RobloxWithdrawalTyped | { resourcetype: 'SkinsbackWithdrawal' } & SkinsbackWithdrawalFullTyped | { resourcetype: 'StandoffWithdrawal' } & StandoffWithdrawalTyped;

export function WithdrawalFromJSON(json: any): Withdrawal {
    return WithdrawalFromJSONTyped(json, false);
}

export function WithdrawalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Withdrawal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'GenshinWithdrawal':
            return {...GenshinWithdrawalTypedFromJSONTyped(json, true), resourcetype: 'GenshinWithdrawal'};
        case 'PUBGWithdrawal':
            return {...PUBGWithdrawalTypedFromJSONTyped(json, true), resourcetype: 'PUBGWithdrawal'};
        case 'RobloxWithdrawal':
            return {...RobloxWithdrawalTypedFromJSONTyped(json, true), resourcetype: 'RobloxWithdrawal'};
        case 'SkinsbackWithdrawal':
            return {...SkinsbackWithdrawalFullTypedFromJSONTyped(json, true), resourcetype: 'SkinsbackWithdrawal'};
        case 'StandoffWithdrawal':
            return {...StandoffWithdrawalTypedFromJSONTyped(json, true), resourcetype: 'StandoffWithdrawal'};
        default:
            throw new Error(`No variant of Withdrawal exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function WithdrawalToJSON(value?: Withdrawal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'GenshinWithdrawal':
            return GenshinWithdrawalTypedToJSON(value);
        case 'PUBGWithdrawal':
            return PUBGWithdrawalTypedToJSON(value);
        case 'RobloxWithdrawal':
            return RobloxWithdrawalTypedToJSON(value);
        case 'SkinsbackWithdrawal':
            return SkinsbackWithdrawalFullTypedToJSON(value);
        case 'StandoffWithdrawal':
            return StandoffWithdrawalTypedToJSON(value);
        default:
            throw new Error(`No variant of Withdrawal exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

