// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `bubble_gum` - Bubble Gum
 * * `sun_beams` - Sun Beams
 * * `heaven_blue` - Heaven Blue
 * * `lavender` - Lavender
 * * `olive_green` - Olive Green
 * * `coral_reef` - Coral Reef
 * * `topaz` - Topaz
 * * `pacific_gradient` - Pacific Gradient
 * * `morning_gradient` - Morning Gradient
 * * `club_gradient` - Club Gradient
 * * `highest_standard` - Highest Standard
 * * `admin` - Admin
 * @export
 */
export const NicknameDecorationsEnum = {
    BubbleGum: 'bubble_gum',
    SunBeams: 'sun_beams',
    HeavenBlue: 'heaven_blue',
    Lavender: 'lavender',
    OliveGreen: 'olive_green',
    CoralReef: 'coral_reef',
    Topaz: 'topaz',
    PacificGradient: 'pacific_gradient',
    MorningGradient: 'morning_gradient',
    ClubGradient: 'club_gradient',
    HighestStandard: 'highest_standard',
    Admin: 'admin'
} as const;
export type NicknameDecorationsEnum = typeof NicknameDecorationsEnum[keyof typeof NicknameDecorationsEnum];


export function NicknameDecorationsEnumFromJSON(json: any): NicknameDecorationsEnum {
    return NicknameDecorationsEnumFromJSONTyped(json, false);
}

export function NicknameDecorationsEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NicknameDecorationsEnum {
    return json as NicknameDecorationsEnum;
}

export function NicknameDecorationsEnumToJSON(value?: NicknameDecorationsEnum | null): any {
    return value as any;
}

