// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NextFloorResponseStatusEnum } from './NextFloorResponseStatusEnum';
import {
    NextFloorResponseStatusEnumFromJSON,
    NextFloorResponseStatusEnumFromJSONTyped,
    NextFloorResponseStatusEnumToJSON,
} from './NextFloorResponseStatusEnum';

/**
 * 
 * @export
 * @interface NextFloorResponse
 */
export interface NextFloorResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof NextFloorResponse
     */
    bombLocations: Array<number>;
    /**
     * 
     * @type {NextFloorResponseStatusEnum}
     * @memberof NextFloorResponse
     */
    status: NextFloorResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof NextFloorResponse
     */
    rate: number;
}

/**
 * Check if a given object implements the NextFloorResponse interface.
 */
export function instanceOfNextFloorResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bombLocations" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "rate" in value;

    return isInstance;
}

export function NextFloorResponseFromJSON(json: any): NextFloorResponse {
    return NextFloorResponseFromJSONTyped(json, false);
}

export function NextFloorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextFloorResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bombLocations': json['bomb_locations'],
        'status': NextFloorResponseStatusEnumFromJSON(json['status']),
        'rate': json['rate'],
    };
}

export function NextFloorResponseToJSON(value?: NextFloorResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bomb_locations': value.bombLocations,
        'status': NextFloorResponseStatusEnumToJSON(value.status),
        'rate': value.rate,
    };
}

