// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPayNotifyRequest
 */
export interface GetPayNotifyRequest {
    /**
     * 
     * @type {number}
     * @memberof GetPayNotifyRequest
     */
    wALLETID: number;
    /**
     * 
     * @type {string}
     * @memberof GetPayNotifyRequest
     */
    wALLETTYPE: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayNotifyRequest
     */
    sUM: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayNotifyRequest
     */
    oRDERID: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayNotifyRequest
     */
    pTYPE: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayNotifyRequest
     */
    pWALLET?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayNotifyRequest
     */
    pAYMENTID: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayNotifyRequest
     */
    sIGN: string;
}

/**
 * Check if a given object implements the GetPayNotifyRequest interface.
 */
export function instanceOfGetPayNotifyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "wALLETID" in value;
    isInstance = isInstance && "wALLETTYPE" in value;
    isInstance = isInstance && "sUM" in value;
    isInstance = isInstance && "oRDERID" in value;
    isInstance = isInstance && "pTYPE" in value;
    isInstance = isInstance && "pAYMENTID" in value;
    isInstance = isInstance && "sIGN" in value;

    return isInstance;
}

export function GetPayNotifyRequestFromJSON(json: any): GetPayNotifyRequest {
    return GetPayNotifyRequestFromJSONTyped(json, false);
}

export function GetPayNotifyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPayNotifyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wALLETID': json['WALLET_ID'],
        'wALLETTYPE': json['WALLET_TYPE'],
        'sUM': json['SUM'],
        'oRDERID': json['ORDER_ID'],
        'pTYPE': json['P_TYPE'],
        'pWALLET': !exists(json, 'P_WALLET') ? undefined : json['P_WALLET'],
        'pAYMENTID': json['PAYMENT_ID'],
        'sIGN': json['SIGN'],
    };
}

export function GetPayNotifyRequestToJSON(value?: GetPayNotifyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WALLET_ID': value.wALLETID,
        'WALLET_TYPE': value.wALLETTYPE,
        'SUM': value.sUM,
        'ORDER_ID': value.oRDERID,
        'P_TYPE': value.pTYPE,
        'P_WALLET': value.pWALLET,
        'PAYMENT_ID': value.pAYMENTID,
        'SIGN': value.sIGN,
    };
}

