// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `active` - Active
 * * `completed` - Completed
 * * `canceled` - Canceled
 * @export
 */
export const WithdrawalStatusEnum = {
    Active: 'active',
    Completed: 'completed',
    Canceled: 'canceled'
} as const;
export type WithdrawalStatusEnum = typeof WithdrawalStatusEnum[keyof typeof WithdrawalStatusEnum];


export function WithdrawalStatusEnumFromJSON(json: any): WithdrawalStatusEnum {
    return WithdrawalStatusEnumFromJSONTyped(json, false);
}

export function WithdrawalStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalStatusEnum {
    return json as WithdrawalStatusEnum;
}

export function WithdrawalStatusEnumToJSON(value?: WithdrawalStatusEnum | null): any {
    return value as any;
}

