// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FooterValuesCounter
 */
export interface FooterValuesCounter {
    /**
     * 
     * @type {number}
     * @memberof FooterValuesCounter
     */
    wheelBets: number;
    /**
     * 
     * @type {number}
     * @memberof FooterValuesCounter
     */
    minerBets: number;
    /**
     * 
     * @type {number}
     * @memberof FooterValuesCounter
     */
    ladderBets: number;
    /**
     * 
     * @type {number}
     * @memberof FooterValuesCounter
     */
    crashBets: number;
    /**
     * 
     * @type {number}
     * @memberof FooterValuesCounter
     */
    casesOpened: number;
    /**
     * 
     * @type {number}
     * @memberof FooterValuesCounter
     */
    players: number;
}

/**
 * Check if a given object implements the FooterValuesCounter interface.
 */
export function instanceOfFooterValuesCounter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "wheelBets" in value;
    isInstance = isInstance && "minerBets" in value;
    isInstance = isInstance && "ladderBets" in value;
    isInstance = isInstance && "crashBets" in value;
    isInstance = isInstance && "casesOpened" in value;
    isInstance = isInstance && "players" in value;

    return isInstance;
}

export function FooterValuesCounterFromJSON(json: any): FooterValuesCounter {
    return FooterValuesCounterFromJSONTyped(json, false);
}

export function FooterValuesCounterFromJSONTyped(json: any, ignoreDiscriminator: boolean): FooterValuesCounter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wheelBets': json['wheel_bets'],
        'minerBets': json['miner_bets'],
        'ladderBets': json['ladder_bets'],
        'crashBets': json['crash_bets'],
        'casesOpened': json['cases_opened'],
        'players': json['players'],
    };
}

export function FooterValuesCounterToJSON(value?: FooterValuesCounter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wheel_bets': value.wheelBets,
        'miner_bets': value.minerBets,
        'ladder_bets': value.ladderBets,
        'crash_bets': value.crashBets,
        'cases_opened': value.casesOpened,
        'players': value.players,
    };
}

