// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Ban } from './Ban';
import {
    BanFromJSON,
    BanFromJSONTyped,
    BanToJSON,
} from './Ban';
import type { ChatMessage } from './ChatMessage';
import {
    ChatMessageFromJSON,
    ChatMessageFromJSONTyped,
    ChatMessageToJSON,
} from './ChatMessage';
import type { UserShort } from './UserShort';
import {
    UserShortFromJSON,
    UserShortFromJSONTyped,
    UserShortToJSON,
} from './UserShort';

/**
 * 
 * @export
 * @interface Chat
 */
export interface Chat {
    /**
     * 
     * @type {number}
     * @memberof Chat
     */
    cooldown: number;
    /**
     * 
     * @type {Ban}
     * @memberof Chat
     */
    readonly ban: Ban | null;
    /**
     * 
     * @type {ChatMessage}
     * @memberof Chat
     */
    readonly pinnedMessage: ChatMessage | null;
    /**
     * 
     * @type {UserShort}
     * @memberof Chat
     */
    readonly moderator: UserShort | null;
    /**
     * 
     * @type {boolean}
     * @memberof Chat
     */
    readonly isNicknameChangeRequested: boolean;
}

/**
 * Check if a given object implements the Chat interface.
 */
export function instanceOfChat(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cooldown" in value;
    isInstance = isInstance && "ban" in value;
    isInstance = isInstance && "pinnedMessage" in value;
    isInstance = isInstance && "moderator" in value;
    isInstance = isInstance && "isNicknameChangeRequested" in value;

    return isInstance;
}

export function ChatFromJSON(json: any): Chat {
    return ChatFromJSONTyped(json, false);
}

export function ChatFromJSONTyped(json: any, ignoreDiscriminator: boolean): Chat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cooldown': json['cooldown'],
        'ban': BanFromJSON(json['ban']),
        'pinnedMessage': ChatMessageFromJSON(json['pinned_message']),
        'moderator': UserShortFromJSON(json['moderator']),
        'isNicknameChangeRequested': json['is_nickname_change_requested'],
    };
}

export function ChatToJSON(value?: Chat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cooldown': value.cooldown,
    };
}

