// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreparedWithdrawalPUBGPack } from './PreparedWithdrawalPUBGPack';
import {
    PreparedWithdrawalPUBGPackFromJSON,
    PreparedWithdrawalPUBGPackFromJSONTyped,
    PreparedWithdrawalPUBGPackToJSON,
} from './PreparedWithdrawalPUBGPack';

/**
 * 
 * @export
 * @interface PreparedMooGoldWithdrawal
 */
export interface PreparedMooGoldWithdrawal {
    /**
     * 
     * @type {number}
     * @memberof PreparedMooGoldWithdrawal
     */
    totalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PreparedMooGoldWithdrawal
     */
    amountBullcoinsToReturn: number;
    /**
     * 
     * @type {Array<PreparedWithdrawalPUBGPack>}
     * @memberof PreparedMooGoldWithdrawal
     */
    packs: Array<PreparedWithdrawalPUBGPack>;
}

/**
 * Check if a given object implements the PreparedMooGoldWithdrawal interface.
 */
export function instanceOfPreparedMooGoldWithdrawal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "amountBullcoinsToReturn" in value;
    isInstance = isInstance && "packs" in value;

    return isInstance;
}

export function PreparedMooGoldWithdrawalFromJSON(json: any): PreparedMooGoldWithdrawal {
    return PreparedMooGoldWithdrawalFromJSONTyped(json, false);
}

export function PreparedMooGoldWithdrawalFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreparedMooGoldWithdrawal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalAmount': json['total_amount'],
        'amountBullcoinsToReturn': json['amount_bullcoins_to_return'],
        'packs': ((json['packs'] as Array<any>).map(PreparedWithdrawalPUBGPackFromJSON)),
    };
}

export function PreparedMooGoldWithdrawalToJSON(value?: PreparedMooGoldWithdrawal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_amount': value.totalAmount,
        'amount_bullcoins_to_return': value.amountBullcoinsToReturn,
        'packs': ((value.packs as Array<any>).map(PreparedWithdrawalPUBGPackToJSON)),
    };
}

