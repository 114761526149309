<template>
  <FlagProvider
    :unleash-client="unleashClient"
    :start-client="false"
  >
    <NuxtLayout>
      <NuxtPage />
      <ClientOnly>
        <Transition name="notification">
          <Notification v-if="notificationsStore.notificationIsRequired" />
        </Transition>
      </ClientOnly>
    </NuxtLayout>
  </FlagProvider>
</template>

<script setup lang="ts">
import { FlagProvider } from "@unleash/proxy-client-vue";
import {
  LOGIN_EVENT,
  LOGIN_EVENT_TARGET,
} from "~/event-targets/loginEventTarget";

const notificationsStore = useNotificationsStore();
const userStore = useUserStore();
const unleashManager = useUnleashManager();
const unleashClient = unleashManager.useUnleashClient();

await userStore.handleVisit();
await unleashClient.start();

if (isClient()) {
  setTimeout(() => {
    // если пользователь уже авторизован и дал согласие на уведомления
    useFirebasePushes();
    // если предыдущий шаг был пропущен
    LOGIN_EVENT_TARGET.addEventListener(LOGIN_EVENT, () => {
      setTimeout(useFirebasePushes, 3000);
    });
  }, 100);
}
</script>
